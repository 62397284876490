<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">Integración WEB</v-card-title>
            <v-card-text>
                <v-row class="mt-2">
                    <v-col cols="12" md="3">
                        <v-select class='text-caption' v-model="bodega" :items="listabodega" label="Local" item-text="name" item-value='code' dense outlined rounded filled disabled></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                        <strong v-if="interrumpe==true" class="text-caption red--text">El Proceso no finalizó correctamente</strong>
                        <br>
                        <strong v-if="interrumpe==true" class="text-caption red--text">{{cuenta_save}} de {{ cuenta_total }} productos grabados</strong>
                    </v-col>
                </v-row>
                <div class="mt-n12">
                <v-data-table dense :headers="allselect" :items="itemselect" hide-default-footer class="text-size">
                    <template v-slot:item.vista="{ item }">
                        <v-icon class="ml-2" @click="ckAll(item,'vista')" v-if="item.vista!='si'" color='green'>check_box_outline_blank</v-icon>
                        <v-icon class="ml-2" @click="ckAll(item,'vista')" v-else color='green'>check_box</v-icon>
                    </template>
                    <template v-slot:item.plocal="{ item }">
                        <v-icon class="ml-2" @click="ckAll(item,'plocal')" v-if="item.plocal!='si'" color='green'>check_box_outline_blank</v-icon>
                        <v-icon class="ml-2" @click="ckAll(item,'plocal')" v-else color='green'>check_box</v-icon>
                    </template>
                    <template v-slot:item.pmayor="{ item }">
                        <v-icon class="ml-2" @click="ckAll(item,'pmayor')" v-if="item.pmayor!='si'" color='green'>check_box_outline_blank</v-icon>
                        <v-icon class="ml-2" @click="ckAll(item,'pmayor')" v-else color='green'>check_box</v-icon>
                    </template>
                    <template v-slot:item.poferta="{ item }">
                        <v-icon class="ml-2" @click="ckAll(item,'poferta')" v-if="item.poferta!='si'" color='green'>check_box_outline_blank</v-icon>
                        <v-icon class="ml-2" @click="ckAll(item,'poferta')" v-else color='green'>check_box</v-icon>
                    </template>
                    <template v-slot:item.destacado="{ item }">
                        <!-- <v-icon class="ml-2" v-if="item.destacado!='si'" color='green'>check_box_outline_blank</v-icon> -->
                        <!-- <v-icon class="ml-2" v-else color='grey'>check_box</v-icon> -->
                        <h4 v-if="item.destacado!='si' || item.destacado=='si'">Max. {{maximo}}</h4>
                    </template>
                    <template v-slot:item.promocion="{ item }">
                        <!-- <v-icon class="ml-2" v-if="item.promocion!='si'" color='green'>check_box_outline_blank</v-icon> -->
                        <!-- <v-icon class="ml-2" v-else color='grey'>check_box</v-icon>-->
                        <h4 v-if="item.promocion!='si' || item.promocion=='si'">Max. {{maximo}}</h4>
                    </template> 
                    <template v-slot:item.campania="{ item }">
                        <v-icon class="ml-2" @click="ckAll(item,'campania')" v-if="item.campania!='si'" color='green'>check_box_outline_blank</v-icon>
                        <v-icon class="ml-2" @click="ckAll(item,'campania')" v-else color='green'>check_box</v-icon>
                        <v-icon class="ml-2" small color="indigo">add_circle</v-icon>
                    </template>
                </v-data-table>
                <v-data-table :loading="progress"  loading-text="Cargando ..." :search="search" dark dense :headers="header" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                    <template v-slot:item.vista="{ item }">
                        <v-icon class="ml-2" @click="ckItem(item,'vista')" v-if="item.vista!='si'" color='green'>check_box_outline_blank</v-icon>
                        <v-icon class="ml-2" @click="ckItem(item,'vista')" v-else color='green'>check_box</v-icon>
                    </template>
                    <template v-slot:item.plocal="{ item }">
                        <v-icon class="ml-2" @click="ckItem(item,'plocal')" v-if="item.plocal!='si'" color='green'>check_box_outline_blank</v-icon>
                        <v-icon class="ml-2" @click="ckItem(item,'plocal')" v-else color='green'>check_box</v-icon>
                    </template>
                    <template v-slot:item.pmayor="{ item }">
                        <v-icon class="ml-2" @click="ckItem(item,'pmayor')" v-if="item.pmayor!='si'" color='green'>check_box_outline_blank</v-icon>
                        <v-icon class="ml-2" @click="ckItem(item,'pmayor')" v-else color='green'>check_box</v-icon>
                    </template>
                    <template v-slot:item.poferta="{ item }">
                        <v-icon class="ml-2" @click="ckItem(item,'poferta')" v-if="item.poferta!='si'" color='green'>check_box_outline_blank</v-icon>
                        <v-icon class="ml-2" @click="ckItem(item,'poferta')" v-else color='green'>check_box</v-icon>
                    </template>
                    <template v-slot:item.destacado="{ item }">
                        <v-icon class="ml-2" @click="ckItem(item,'destacado')" v-if="item.destacado!='si'" color='green'>check_box_outline_blank</v-icon>
                        <v-icon class="ml-2" @click="ckItem(item,'destacado')" v-else color='green'>check_box</v-icon>
                    </template>
                    <template v-slot:item.promocion="{ item }">
                        <v-icon class="ml-2" @click="ckItem(item,'promocion')" v-if="item.promocion!='si'" color='green'>check_box_outline_blank</v-icon>
                        <v-icon class="ml-2" @click="ckItem(item,'promocion')" v-else color='green'>check_box</v-icon>
                    </template>
                    <template v-slot:item.campania="{ item }">
                        <v-icon class="ml-2" @click="ckItem(item,'campania')" v-if="item.campania!='si'" color='green'>check_box_outline_blank</v-icon>
                        <v-icon class="ml-2" @click="ckItem(item,'campania')" v-else color='green'>check_box</v-icon>
                    </template>
                </v-data-table>
                <v-row class="mt-2">
                    <v-col cols="12" md="6">
                        <v-pagination total-visible="10"  v-model="page" :length="pageCount"></v-pagination>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field class='ml-2 mt-3 text-caption' v-model="search" label="Buscar Producto" dense clearable></v-text-field>                    
                    </v-col>
                    <v-col cols="12" md="3" class="text-center">
                        <v-btn class="mt-2" @click="saveIntegraWeb()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Guardar Cambios</span></v-btn>
                    </v-col>
                </v-row>
                </div>
            </v-card-text>
        </v-card>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <!-- <dialogo :loading="loading"  />  -->
        <!-- dialogo carga -->
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
            <v-card-text>
                <v-row class="mt-2">
                    <v-col cols="12" md="12">
                        <span>Procesando ...</span>
                        <v-progress-linear indeterminate color="white"></v-progress-linear>
                    </v-col>
                    <v-col cols="12" md="12" class="mt-n4 text-center">
                        <strong class="text-body-2 font-italic">{{cuenta_save}} de {{ cuenta_total }} productos</strong>
                    </v-col>
                </v-row>
            </v-card-text>
            </v-card>
        </v-dialog>          
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 30px !important;
    /* background-color: lightyellow; */
}
</style><script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from "axios";
export default {
    components:{dialogo, snack},
    name:'IntegraWeb',
    props: ['usu','contrato'], 
    data(){
        return{
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 7,
            header: [
                { text: 'Sku', align: 'start', sortable: true, value: 'sku', width:'12%' },
                { text: 'Producto', align: 'start', sortable: true, value: 'producto', width:'25%' },
                { text: 'Visible', align: 'start', sortable: false, value: 'vista', width:'9%' },
                { text: '($) Local', align: 'start', sortable: false, value: 'plocal', width:'9%' },
                { text: '($) Mayor', align: 'start', sortable: false, value: 'pmayor', width:'9%' },
                { text: '($) Oferta', align: 'start', sortable: false, value: 'poferta', width:'9%' },
                { text: 'Destacado', align: 'start', sortable: false, value: 'destacado', width:'9%' },
                { text: 'Promoción', align: 'start', sortable: false, value: 'promocion', width:'9%' },
                { text: 'Campaña', align: 'start', sortable: false, value: 'campania', width:'9%' },
            ],
            items: [],
            progress:false,
            allselect: [
                { text: '', align: 'end', sortable: false, value: 'todos', width:'37%' },
                { text: '', align: 'start', sortable: false, value: 'vista', width:'9%' },
                { text: '', align: 'start', sortable: false, value: 'plocal', width:'9%' },
                { text: '', align: 'start', sortable: false, value: 'pmayor', width:'9%' },
                { text: '', align: 'start', sortable: false, value: 'poferta', width:'9%' },
                { text: '', align: 'start', sortable: false, value: 'destacado', width:'9%' },
                { text: '', align: 'start', sortable: false, value: 'promocion', width:'9%' },
                { text: '', align: 'start', sortable: false, value: 'campania', width:'9%' },
            ],
            itemselect: [
                {
                    todos:'Seleccionar Todos',
                    vista: 'no',
                    plocal: 'no',
                    pmayor: 'no', 
                    poferta: 'no', 
                    destacado: 'no', 
                    promocion: 'no', 
                    campana: 'no'
                }
            ],
            maximo:6,
            search:'',
            bodega:0,
            listabodega:[],
            checkProductos:[],
            cuenta_save:0,
            cuenta_total:0,
            interrumpe:false,

        }
    },
    async mounted(){
        this.checkProductos=[];
        await this.bodegas();
        await this.productos()
    },
    methods:{
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        async bodegas(){
            try {
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/'+ this.contrato);
                const datos=response.data;
                console.log(datos);
                this.listabodega=[];
                datos.forEach(element => {
                    this.listabodega.push({
                        'name':element.Nombre,
                        'code':element.ID
                    });
                });
            } catch (error) {
                console.log(error)
            }
        },
        async productos(){
            try {
                this.progress=true;
                this.items=[];
                this.checkProductos=[];
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/productos/integracion/siteweb/all/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.bodega = datos[0].Bodega;
                    for (let index = 0; index < datos.length; index++) {
                        const element = datos[index];
                        this.items.push(
                            {
                                id:index,
                                sku:element.Sku,
                                producto:element.Nombre,
                                vista: element.Vista,
                                plocal: element.Detalle,
                                pmayor: element.Mayor,
                                poferta: element.Oferta,
                                destacado: element.Destacado,
                                promocion: element.Promocion,
                                campania: element.Campania,
                                bodega: element.Bodega,
                            }
                        )                            
                    }
                } else {
                    this.Msg='Productos no Existen';
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }                                        
                this.progress=false;                          
            } catch (error) {
                console.log(error)
            }
        },
        async saveIntegraWeb(){
            try {
                this.interrumpe=false;
                if(parseInt(this.bodega)!=0){
                    this.loading=true;
                    var registros=0;
                    this.cuenta_save=0;
                    this.cuenta_total=0;
                    //calcular barra de progreso
                    this.cuenta_total=parseFloat(this.checkProductos.length);
                    console.log(this.checkProductos)
                    for (let index = 0; index < this.checkProductos.length; index++) {
                        const element = this.checkProductos[index];
                        var params={
                            'contrato': this.contrato,
                            'sku': element.sku,
                            'vista': element.vista,
                            'detalle': element.plocal,
                            'mayor': element.pmayor,
                            'oferta': element.poferta,
                            'destacado': element.destacado,
                            'promocion': element.promocion,
                            'campania': element.campania,
                            'stock': element.bodega,
                        };
                        //borramos el sku
                        const response = await axios.delete('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/productos/integracion/siteweb/delete/'+this.contrato+'/'+element.sku);
                        const datos=response.data;
                        if (datos.existe!='error'){
                            //grabamos el sku
                            const response1 = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/productos/integracion/siteweb/nueva/',params);
                            const datos1=response1.data;
                            if (datos1=='si'){
                                registros++
                                this.cuenta_save=registros;
                            }
                        }                       
                    }
                    if(registros!=0){
                        this.Msg=registros + ' Productos Grabados';
                        this.msgbox=true;
                        this.color='success';
                        this.showSnack();
                    } else {
                        this.Msg='Productos no Grabados';
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                    this.loading=false
                } else {
                    this.Msg='Debe asignar Bodega';
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } catch (error) {
                console.log(error);
                this.loading=false;
                this.interrumpe=true;
                this.productos();
            }
        },
        ckItem(item,cell){
            switch (cell) {
                case 'vista':
                     if(item.vista!='si'){
                         item.vista='si'
                     } else {
                         item.vista='no'
                     }
                break;
                case 'plocal':
                     if(item.plocal!='si'){
                         item.plocal='si'
                     } else {
                         item.plocal='no'
                     }

                break;
                case 'pmayor':
                     if(item.pmayor!='si'){
                         item.pmayor='si'
                     } else {
                         item.pmayor='no'
                     }
                break;
                case 'poferta':
                     if(item.poferta!='si'){
                         item.poferta='si'
                     } else {
                         item.poferta='no'
                     }
                break;
                case 'destacado':
                    //validar cantidad de si
                    const filtro_destacado=this.items.filter(res => res.destacado=='si');
                    if(filtro_destacado.length>=0 && filtro_destacado.length<parseInt(this.maximo)){
                        if(item.destacado!='si'){
                            item.destacado='si'
                        } else {
                            item.destacado='no'
                        }
                    } else {
                        if(item.destacado!='si'){
                            this.Msg='No puede superar el maximo';
                            this.msgbox=true;
                            this.color='warning';
                            this.showSnack();
                        } else {
                            item.destacado='no'
                        }
                    }
                break;
                case 'promocion':
                    //validar cantidad de si
                    const filtro_promocion=this.items.filter(res => res.promocion=='si');
                    if(filtro_promocion.length>=0 && filtro_promocion.length<parseInt(this.maximo)){
                        if(item.promocion!='si'){
                            item.promocion='si'
                        } else {
                            item.promocion='no'
                        }
                    } else {
                        if(item.promocion!='si'){
                            this.Msg='No puede superar el maximo';
                            this.msgbox=true;
                            this.color='warning';
                            this.showSnack();
                        } else {
                            item.promocion='no'
                        }
                    }
                break;
                case 'campania':
                     if(item.campania!='si'){
                         item.campania='si'
                     } else {
                         item.campania='no'
                     }
                break;
            };
            const found = this.checkProductos.find( res => res.sku == item.sku);
            if(found){
                for (let index = 0; index < this.checkProductos.length; index++) {
                    const element = this.checkProductos[index];
                    if(parseInt(element.id)==parseInt(item.id) && element.sku==item.sku){
                        this.checkProductos[index].vista = item.vista;
                        this.checkProductos[index].plocal = item.plocal;
                        this.checkProductos[index].pmayor = item.pmayor;
                        this.checkProductos[index].poferta = item.poferta;
                        this.checkProductos[index].destacado = item.destacado;
                        this.checkProductos[index].promocion = item.promocion;
                        this.checkProductos[index].campania = item.campania;
                    }                    
                }
            } else {
                this.checkProductos.push({
                    'id':item.id,
                    'contrato': this.contrato,
                    'sku': item.sku,
                    'vista': item.vista,
                    'plocal': item.plocal,
                    'pmayor': item.pmayor,
                    'poferta': item.poferta,
                    'destacado': item.destacado,
                    'promocion': item.promocion,
                    'campania': item.campania,
                    'bodega': item.bodega,
                })
            }
            console.log(item);
            console.log(this.checkProductos);
        },
        ckAll(item,cell){
            switch (cell) {
                case 'vista':
                    if(item.vista!='si'){
                        this.items.forEach(element => { element.vista='si' });
                        item.vista='si'
                    } else {
                        this.items.forEach(element => { element.vista='no' });
                        item.vista='no'
                    }
                break;
                case 'plocal':
                    if(item.plocal!='si'){
                        this.items.forEach(element => { element.plocal='si' });
                        item.plocal='si'
                    } else {
                        this.items.forEach(element => { element.plocal='no' });
                        item.plocal='no'
                    }
                break;
                case 'pmayor':
                    if(item.pmayor!='si'){
                        this.items.forEach(element => { element.pmayor='si' });
                        item.pmayor='si'
                    } else {
                        this.items.forEach(element => { element.pmayor='no' });
                        item.pmayor='no'
                    }
                break;
                case 'poferta':
                    if(item.poferta!='si'){
                        this.items.forEach(element => { element.poferta='si' });
                        item.poferta='si'
                    } else {
                        this.items.forEach(element => { element.poferta='no' });
                        item.poferta='no'
                    }
                break;
                case 'campania':
                    if(item.campania!='si'){
                        this.items.forEach(element => { element.campania='si' });
                        item.campania='si'
                    } else {
                        this.items.forEach(element => { element.campania='no' });
                        item.campania='no'
                    }
                break;
            }
            this.checkProductos=[];
            this.checkProductos=this.items;
        }
    }
}
</script>