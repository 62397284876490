<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">USUARIO</v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="5">
                            <v-row>
                                <v-col cols="8" md="4">
                                    <v-text-field class='text-caption' :counter="10" v-model="usuario" label="Usuario" outlined dense :disabled="disabledUsuario" :filled="filledUsuario" required></v-text-field>
                                </v-col>
                                <v-col cols="4" md="2"><v-btn @click="verUsuario(usuario)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn></v-col>
                                <v-col cols="12" md="6">
                                    <v-alert class='text-caption' v-if="existe=='si'" dense text type="success">
                                        Existe !
                                    </v-alert>
                                    <v-alert class='text-caption' v-else-if="existe=='no'" dense text type="warning">
                                        Nuevo !
                                    </v-alert>
                                    <v-alert class='text-caption' v-else dense text type="info">
                                        Usuario
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row class="mt-n5">
                                <v-col cols="12" md="7">
                                    <v-text-field class='text-caption' v-model="nombre" label="Nombre" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-text-field class='text-caption' v-model="email" label="Email" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-n5">
                                <!-- <v-col cols="12" md="6">
                                    <v-select class='text-caption' v-model="sucursal" :items="itemsucursal" item-text="name" item-value="code" label="Sucursal" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>                                
                                </v-col> -->
                                <v-col cols="10" md="6">
                                    <v-select class='text-caption' v-on:change="OpenBodega(bodega)" v-model="bodega" :items="itembodega" item-text="name" item-value="code" label="Local" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>                                
                                </v-col>
                                <v-col cols="2" md="1">
                                    <v-icon @click="newLocals()" class="mt-2 ml-n3" color="indigo">add_circle_outline</v-icon>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field class='text-caption' @keypress="onlyNumber" v-model="comision" label="% Comision" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-n5">
                                <v-col cols="12" md="3">
                                    <v-select class='text-caption' v-model="estado" :items="estados" label="Estado" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>
                                </v-col>
                                <v-col cols="12" md="8" class="mt-n5 text-end">
                                    <v-chip class="mt-10" small dark @click="resetPassword()">Resetear Contraseña</v-chip>
                                </v-col>
                            </v-row>
                            <v-row class="mt-n5">
                                <v-col cols="12" md="6">
                                    <v-switch style="margin-top:0px" v-model="Reporte" label="Full Report"></v-switch>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-switch style="margin-top:0px" v-model="Ecommerce" label="Ecommerce"></v-switch>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="7">
                            <v-row class="mt-n8">                           
                                <v-col cols="12" md="3">
                                    <h5 class="mt-5 primary--text">DashBord</h5>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_1_1"></v-switch>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-55px">                           
                                <v-col cols="12" md="3">
                                    <h5 class="mt-5 primary--text">Productos</h5>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_2_1" label="Crear"></v-switch>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_2_2" label="Web"></v-switch>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_2_3" label="Precios"></v-switch>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-55px">                           
                                <v-col cols="12" md="3">
                                    <h5 class="mt-5 primary--text">Compra / Cotización</h5>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_3_1" label="Compra"></v-switch>
                                </v-col>
                                 <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_3_3" label="Cotiza"></v-switch>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-55px">                           
                                <v-col cols="12" md="3">
                                    <h5 class="mt-5 primary--text">Venta / Devolución</h5>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_3_2" label="Venta"></v-switch>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_3_5" label="Express"></v-switch>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_3_4" label="Devolución"></v-switch>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-55px">                           
                                <v-col cols="12" md="3">
                                    <h5 class="mt-5 primary--text">Flujo de Caja</h5>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_7_1" label="Pagos"></v-switch>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_7_2" label="Flujo"></v-switch>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_7_3" label="Conciliación"></v-switch>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-55px">                           
                                <v-col cols="12" md="3">
                                    <h5 class="mt-5 primary--text">Inventario</h5>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_4_1" label="Stock"></v-switch>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_4_2" label="Traspaso"></v-switch>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_4_3" label="Ajuste"></v-switch>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-55px">                           
                                <v-col cols="12" md="3">
                                    <h5 class="mt-5 primary--text">Balance Cartera</h5>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_5_1"></v-switch>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-55px">                           
                                <v-col cols="12" md="3">
                                    <h5 class="mt-5 primary--text">Credito Cobranza</h5>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_8_1" label="Pago Cliente"></v-switch>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-switch v-model="Permiso_8_2" label="Pago Proveedor"></v-switch>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-55px">                           
                                <v-col cols="12" md="3">
                                    <h5 class="mt-5 primary--text">Configuracion</h5>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_6_1" label="Proveedor"></v-switch>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_6_2" label="Cliente"></v-switch>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_6_3" label="Usuario"></v-switch>
                                </v-col>
                            </v-row>
                        <div class="mt-2 float-end">
                            <v-btn  @click="newUsuario()" class="mr-5" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                            <v-btn @click="saveUsuario()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                        </div>                            
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions style="margin-top:-35px">
            </v-card-actions>
        </v-card>
        <!-- Dialogo Sucursal -->
        <v-dialog v-model="dialogSucursal" transition="dialog-bottom-transition" max-width="600" persistent>
            <v-card>
            <v-toolbar color="primary" dark>Nueva Sucursal</v-toolbar>
            <v-card-text class="mt-5">
                <v-text-field class='text-caption' v-model="newSucursal" label="Nombre Sucursal" :disabled="disabledSucursal" :filled="filledSucursal" outlined dense required></v-text-field>
                <v-row>
                    <v-col cols="12" md="6">
                        <div v-if="alertaSucursal!='si'">
                            <v-btn @click="newSucursales()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                        </div>
                        <div v-else>
                            <v-btn small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-alert class='text-caption' v-if="alertaSucursal=='si'" dense text type="success">
                            Sucursal Creada con Exito !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else-if="alertaSucursal=='no'" dense text type="error">
                            No ha Ingresado Sucursal !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else dense text type="info">
                            Dato Obligatorio !!!
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialogSucursal=false" color="indigo">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialogo Bodega -->
        <v-dialog v-model="dialogBodega" transition="dialog-bottom-transition" max-width="600" persistent>
            <v-card>
            <v-toolbar color="primary" dark>Nueva Bodega</v-toolbar>
            <v-card-text class="mt-5">
                <v-text-field class='text-caption' v-model="newbodega" label="Nombre Bodega" :disabled="disabledBodega" :filled="filledBodega" outlined dense required></v-text-field>
                <v-row>
                    <v-col cols="12" md="6">
                        <div v-if="alertabodega!='si'">
                            <v-btn @click="newBodega()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                        </div>
                        <div v-else>
                            <v-btn small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-alert class='text-caption' v-if="alertabodega=='si'" dense text type="success">
                            Bodega Creada con Exito !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else-if="alertabodega=='no'" dense text type="error">
                            No ha Ingresado Bodega !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else dense text type="info">
                            Dato Obligatorio !!!
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="CloseBodega()" color="indigo">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- DIALOGO NEW LOCAL -->
        <v-dialog v-model="dialogLocal" transition="dialog-bottom-transition" max-width="600" persistent>
            <v-card>
            <v-toolbar color="primary" dark>Mantenedor de Locales</v-toolbar>
            <v-card-text class="mt-5">
                <v-row>
                    <v-col cols="12" md="5">
                        <v-text-field class='text-caption' v-model="newLocal" label="Nuevo Local" :disabled="disabledLocal" :filled="filledLocal" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-alert class='text-caption' v-if="alertaLocal=='si'" dense text type="success">
                            Local Creado !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else-if="alertaLocal=='no'" dense text type="error">
                            Sin Local !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else dense text type="info">
                            Dato Obligatorio !!!
                        </v-alert>
                    </v-col>
                    <v-col cols="12" md="2">
                        <div v-if="alertaLocal!='si'">
                           <v-btn @click="addLocals()" small fab dark color="indigo"><v-icon>playlist_add</v-icon></v-btn>
                            <!-- <v-btn @click="newCategoria()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn> -->
                        </div>
                        <div v-else>
                            <v-btn small fab dark color="grey"><v-icon>playlist_add</v-icon></v-btn>
                            <!-- <v-btn small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn> -->
                        </div>
                    </v-col>
                </v-row>
                <v-row class="mt-n8">
                    <v-col cols="12" md="12">
                        <v-data-table dense dark :headers="localHead" :items="localList" :page.sync="pageLocal" :items-per-page="itemsPerPageLocal"  @page-count="pageCountLocal = $event" hide-default-footer class="elevation-1 text-size">
                            <template v-slot:item.accion="{ item }">
                                <v-icon small class="white--text" @click="deleteLocal(item)">delete</v-icon>
                            </template>
                        </v-data-table>
                        <v-pagination v-model="pageLocal" :length="pageCountLocal" :total-visible="5"></v-pagination>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="CloseLocal()" color="indigo">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />
    </div>
</template>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Usuario',
    components:{dialogo, snack},
    props:['usu','contrato'],
    data(){
        return{
            //contrato:'123',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            existe:'',            
            disabledUsuario:false,
            filledUsuario:false,
            disabledDatos:true,
            filledDatos:true,
            usuario:'',
            nombre:'',
            email:'',
            comision:0,
            Permiso_1_1:false,
            Permiso_2_1:false,
            Permiso_2_2:false,
            Permiso_2_3:false,
            Permiso_3_1:false,
            Permiso_3_2:false,
            Permiso_3_3:false,
            Permiso_3_4:false,
            Permiso_3_5:false,
            Permiso_4_1:false,
            Permiso_4_2:false,
            Permiso_4_3:false,
            Permiso_5_1:false,
            Permiso_6_1:false,
            Permiso_6_2:false,
            Permiso_6_3:false,
            Permiso_7_1:false,
            Permiso_7_2:false,
            Permiso_7_3:false,
            Permiso_8_1:false,
            Permiso_8_2:false,
            nPermiso_1_1:0,
            nPermiso_2_1:0,
            nPermiso_2_2:0,
            nPermiso_2_3:0,
            nPermiso_3_1:0,
            nPermiso_3_2:0,
            nPermiso_3_3:0,
            nPermiso_3_4:0,
            nPermiso_3_5:0,
            nPermiso_4_1:0,
            nPermiso_4_2:0,
            nPermiso_4_3:0,
            nPermiso_5_1:0,
            nPermiso_6_1:0,
            nPermiso_6_2:0,
            nPermiso_6_3:0,
            nPermiso_7_1:0,
            nPermiso_7_2:0,
            nPermiso_7_3:0,
            nPermiso_8_1:0,
            nPermiso_8_2:0,
            Reporte:false,
            Ecommerce:false,
            nReporte:0,
            nEcommerce:0,
            alertaSucursal:'',
            disabledSucursal:false,
            filledSucursal:false,
            newSucursal:'',
            dialogSucursal:false,
            itemsucursal:[],
            sucursal:0,
            itembodega:[],
            bodega:0,
            estados:['V','N'],
            estado:'V',
            //new Bodega
            alertabodega:'',
            disabledBodega:false,
            filledBodega:false,
            newbodega:'',
            dialogBodega:false,

            // new local
            disabledLocal:false,
            filledLocal:false,
            dialogLocal:false,
            alertaLocal:'',
            newLocal:'',
            pageLocal: 1,
            pageCountLocal: 0,
            itemsPerPageLocal: 10,
            localHead:[
                { text: 'ID', align: 'center', sortable: false, value: 'id' },
                { text: 'Descripcion', align: 'start', sortable: false, value: 'name' },
                { text: '', align: 'center', sortable: false, value: 'accion' }
            ],
            localList:[],


        }
    },
    mounted(){
        //this.buscarSucursal();
        this.buscarBodegas()
    },
    methods:{
        async newBodega(){
            try {
                if(this.newbodega!=''){
                    this.disabledBodega=true;
                    this.filledBodega=true;
                    var parametros={
                        'contrato': this.contrato,
                        'nombre': this.newbodega};
                    const response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/nueva',parametros);
                    const datos=response.data;
                    if(datos=='si'){
                        //buscamos el id de la nueva sucursal asignada
                        await this.buscarBodegas();
                        const resultado = this.itembodega.find( dato => dato.name === this.newbodega );
                        this.bodega=resultado.code;
                        this.alertabodega='si';                        
                    } else {
                        this.alertabodega='no';
                    }
                } else {
                    this.alertabodega='no';
                }                
            } catch (error) {
                console.log(error)
            }
        },
        OpenBodega(a) {
            if(a=='0'){
                this.dialogBodega=true;
                this.alertabodega='';
                this.newbodega='';
            }
        },
        CloseBodega() {
            this.newbodega='';
            this.alertabodega='';
            this.disabledBodega=false;
            this.filledBodega=false;
            this.dialogBodega=false;
        },
        // async buscarSucursal(){
        //     const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/sucursales/'+this.contrato);
        //     const datos=response.data;
        //     this.itemsucursal=[];
        //     //this.itemsucursal.push({"code":"0","name":"Nueva"});
        //     if (datos[0].existe=='si'){
        //         datos.forEach(element => {
        //             this.itemsucursal.push({"code":element.ID,"name":element.Nombre})        
        //         });
        //     }
        // },
        async buscarBodegas(){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/'+this.contrato);
            const datos=response.data;
            this.itembodega=[];
            // this.itembodega.push({"code":0,"name":'Nueva'});
            this.localList=[];            
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.itembodega.push({"code":element.ID,"name":element.Nombre});
                    this.localList.push({'id':element.ID,'name':element.Nombre});       
                });
            }
        },
        openDialog(a) {
            console.log(a);
            if(a==0){
                this.alertaSucursal='';
                this.newSucursal='';
                this.dialogSucursal=true;
                 this.disabledSucursal=false;
                this.filledSucursal=false;
            } else {
                this.alertaSucursal='';
                this.newSucursal='';
                this.dialogSucursal=false;
            }
        },
        async newSucursales(){
            try {
                var parametros={
                    'id':this.bodega,
                    'contrato': this.contrato,
                    'nombre': this.newBodega};
                const response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/sucursal/nueva',parametros);
                const datos=response.data;
                if (datos=='si'){
                    console.log('ok');
                }
            } catch (error) {
                console.log(error)
            }
            // if(this.newSucursal!=''){
            //     this.disabledSucursal=true;
            //     this.filledSucursal=true;
            //     var parametros={
            //         'contrato': this.contrato,
            //         'nombre': this.newSucursal};
            //     const response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/sucursal/nueva',parametros);
            //     const datos=response.data;
            //     if (datos=='si'){
            //         // buscar ID de la nueva sucursal
            //         const response1 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/sucursales/'+this.contrato);
            //         const datos1=response1.data;
            //         if (datos1[0].existe=='si'){
            //             datos1.forEach(element => {
            //                 if(element.Nombre==this.newSucursal){
            //                     this.itemsucursal.push({"code":element.ID,"name":element.Nombre});
            //                     this.sucursal=element.ID
            //                 }
            //             });
            //         } 
            //         this.alertaSucursal='si';                
            //     } else {
            //         this.alertaSucursal='no';
            //     }
            // } else {
            //     this.alertaSucursal='no';
            // }
        },

        restoDatos(bool){
            this.disabledDatos=bool,
            this.filledDatos=bool
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        onlyNumber ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        clsInput(){
            this.nombre='';
            this.email='';
            this.comision=0;
            this.Permiso_1_1=false;
            this.Permiso_2_1=false;
            this.Permiso_2_2=false;
            this.Permiso_2_3=false;
            this.Permiso_3_1=false;
            this.Permiso_3_2=false;
            this.Permiso_3_3=false;
            this.Permiso_3_4=false;
            this.Permiso_3_5=false;
            this.Permiso_4_1=false;
            this.Permiso_4_2=false;
            this.Permiso_4_3=false;
            this.Permiso_5_1=false;
            this.Permiso_6_1=false;
            this.Permiso_6_2=false;
            this.Permiso_6_3=false;
            this.Permiso_7_1=false;
            this.Permiso_7_2=false;
            this.Permiso_7_3=false;
            this.Permiso_8_1=false;
            this.Permiso_8_2=false;
            this.nPermiso_1_1=0;
            this.nPermiso_2_1=0;
            this.nPermiso_2_2=0;
            this.nPermiso_2_3=0;
            this.nPermiso_3_1=0;
            this.nPermiso_3_2=0;
            this.nPermiso_3_3=0;
            this.nPermiso_3_4=0;
            this.nPermiso_3_5=0;
            this.nPermiso_4_1=0;
            this.nPermiso_4_2=0;
            this.nPermiso_4_3=0;
            this.nPermiso_5_1=0;
            this.nPermiso_6_1=0;
            this.nPermiso_6_2=0;
            this.nPermiso_6_3=0;
            this.nPermiso_7_1=0;
            this.nPermiso_7_2=0;
            this.nPermiso_7_3=0;
            this.nPermiso_8_1=0;
            this.nPermiso_8_2=0;
            this.Reporte=false;
            this.nReporte=0;
            this.Ecommerce=false;
            this.nEcommerce=0;
            this.alertaSucursal='';
            this.disabledSucursal=false;
            this.filledSucursal=false;
            this.newSucursal='';
            this.dialogSucursal=false;
            //this.itemsucursal=[];
            this.sucursal=0;
            //this.itembodega=[];
            this.bodega=0;
            this.estado='V';
        },
        async verUsuario(xUsu){
             this.loading=true;
             this.msgbox=false;
             this.clsInput();
            if(xUsu.trim()!=''){
                this.disabledUsuario=true;
                this.filledUsuario=true;
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/usuarios/'+this.contrato+'/'+xUsu);
                const datos=response.data;
                //console.log(datos);
                if (datos[0].existe=='no'){
                    this.existe='no'
                    this.restoDatos(false);
                }
                if (datos[0].existe=='error'){
                    this.Msg='Conexion Inestable, intentelo mas tarde'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                    this.newUsuario()
                }
                if (datos[0].existe=='si'){               
                    this.existe='si';
                    this.contrato=datos[0].Contrato;
                    this.usuario=datos[0].Usuario;
                    this.nombre=datos[0].Nombre;
                    this.email=datos[0].Email;
                    this.comision=datos[0].Comision;
                    this.Permiso_1_1=this.BooleanOpc(datos[0].Permiso_1_1);
                    this.Permiso_2_1=this.BooleanOpc(datos[0].Permiso_2_1);
                    this.Permiso_2_2=this.BooleanOpc(datos[0].Permiso_2_2);
                    this.Permiso_2_3=this.BooleanOpc(datos[0].Permiso_2_3);
                    this.Permiso_3_1=this.BooleanOpc(datos[0].Permiso_3_1);
                    this.Permiso_3_2=this.BooleanOpc(datos[0].Permiso_3_2);
                    this.Permiso_3_3=this.BooleanOpc(datos[0].Permiso_3_3);
                    this.Permiso_3_4=this.BooleanOpc(datos[0].Permiso_3_4);
                    this.Permiso_3_5=this.BooleanOpc(datos[0].Permiso_3_5);
                    this.Permiso_4_1=this.BooleanOpc(datos[0].Permiso_4_1);
                    this.Permiso_4_2=this.BooleanOpc(datos[0].Permiso_4_2);
                    this.Permiso_4_3=this.BooleanOpc(datos[0].Permiso_4_3);
                    this.Permiso_5_1=this.BooleanOpc(datos[0].Permiso_5_1);
                    this.Permiso_6_1=this.BooleanOpc(datos[0].Permiso_6_1);
                    this.Permiso_6_2=this.BooleanOpc(datos[0].Permiso_6_2);
                    this.Permiso_6_3=this.BooleanOpc(datos[0].Permiso_6_3);
                    this.Permiso_7_1=this.BooleanOpc(datos[0].Permiso_7_1);
                    this.Permiso_7_2=this.BooleanOpc(datos[0].Permiso_7_2);
                    this.Permiso_7_3=this.BooleanOpc(datos[0].Permiso_7_3);
                    this.Permiso_8_1=this.BooleanOpc(datos[0].Permiso_8_1);
                    this.Permiso_8_2=this.BooleanOpc(datos[0].Permiso_8_2);
                    this.Reporte=this.BooleanOpc(datos[0].Reporte);
                    this.Ecommerce=this.BooleanOpc(datos[0].Ecommerce);
                    this.sucursal=datos[0].Sucursal;
                    this.bodega=datos[0].Bodega;
                    this.estado=datos[0].Estado;
                    this.restoDatos(false);
                } 
            } else {
                this.existe=''
            }
            this.loading=false;
        },
        BooleanOpc(nbolean){
            if(nbolean!=1){
                return false
            } else { return true}
        },
        OpcBoolean(bolean){
            if(bolean!=true){
                return 0
            } else { return 1}
        },
        async saveUsuario(){
            this.loading=true;
            this.msgbox=false;
            if (this.contrato!='' && this.usuario!='' && parseFloat(this.bodega)!=0 &&
                this.nombre!='' && this.email!='' && this.comision!=''){
                this.nPermiso_1_1=this.OpcBoolean(this.Permiso_1_1);
                this.nPermiso_2_1=this.OpcBoolean(this.Permiso_2_1);
                this.nPermiso_2_2=this.OpcBoolean(this.Permiso_2_2);
                this.nPermiso_2_3=this.OpcBoolean(this.Permiso_2_3);
                this.nPermiso_3_1=this.OpcBoolean(this.Permiso_3_1);
                this.nPermiso_3_2=this.OpcBoolean(this.Permiso_3_2);
                this.nPermiso_3_3=this.OpcBoolean(this.Permiso_3_3);
                this.nPermiso_3_4=this.OpcBoolean(this.Permiso_3_4);
                this.nPermiso_3_5=this.OpcBoolean(this.Permiso_3_5);
                this.nPermiso_4_1=this.OpcBoolean(this.Permiso_4_1);
                this.nPermiso_4_2=this.OpcBoolean(this.Permiso_4_2);
                this.nPermiso_4_3=this.OpcBoolean(this.Permiso_4_3);
                this.nPermiso_5_1=this.OpcBoolean(this.Permiso_5_1);
                this.nPermiso_6_1=this.OpcBoolean(this.Permiso_6_1);
                this.nPermiso_6_2=this.OpcBoolean(this.Permiso_6_2);
                this.nPermiso_6_3=this.OpcBoolean(this.Permiso_6_3);
                this.nPermiso_7_1=this.OpcBoolean(this.Permiso_7_1);
                this.nPermiso_7_2=this.OpcBoolean(this.Permiso_7_2);
                this.nPermiso_7_3=this.OpcBoolean(this.Permiso_7_3);
                this.nPermiso_8_1=this.OpcBoolean(this.Permiso_8_1);
                this.nPermiso_8_2=this.OpcBoolean(this.Permiso_8_2);
                this.nReporte=this.OpcBoolean(this.Reporte);
                this.nEcommerce=this.OpcBoolean(this.Ecommerce);
                var parametros={
                    'contrato': this.contrato,
                    'usuario': this.usuario,
                    'nombre': this.nombre,
                    'email': this.email,
                    'comision':this.comision,
                    'permiso_1_1': this.nPermiso_1_1,
                    'permiso_2_1': this.nPermiso_2_1,
                    'permiso_2_2': this.nPermiso_2_2,
                    'permiso_2_3': this.nPermiso_2_3,
                    'permiso_3_1': this.nPermiso_3_1,
                    'permiso_3_2': this.nPermiso_3_2,
                    'permiso_3_3': this.nPermiso_3_3,
                    'permiso_3_4': this.nPermiso_3_4,
                    'permiso_3_5': this.nPermiso_3_5,
                    'permiso_4_1': this.nPermiso_4_1,
                    'permiso_4_2': this.nPermiso_4_2,
                    'permiso_4_3': this.nPermiso_4_3,
                    'permiso_5_1': this.nPermiso_5_1,
                    'permiso_6_1': this.nPermiso_6_1,
                    'permiso_6_2': this.nPermiso_6_2,
                    'permiso_6_3': this.nPermiso_6_3,
                    'permiso_7_1': this.nPermiso_7_1,
                    'permiso_7_2': this.nPermiso_7_2,
                    'permiso_7_3': this.nPermiso_7_3,
                    'permiso_8_1': this.nPermiso_8_1,
                    'permiso_8_2': this.nPermiso_8_2,
                    'reporte': this.nReporte,
                    'ecommerce': this.nEcommerce,
                    'sucursal': this.sucursal,
                    'bodega': this.bodega,
                    'estado': this.estado
                    };
                    
                if (this.existe=='si'){
                    // actualizar
                    const response = await axios.put('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/usuarios/actualiza/'+this.usuario,parametros);
                    const datos=response.data;
                    //console.log(datos);
                    if (datos=='si'){                
                        this.Msg='Modificacion de Usuario Existoso !!!'
                        this.msgbox=true;
                        this.color='success';
                        this.showSnack();
                    } 
                    if (datos=='error'){
                        this.Msg='Conexion Inestable, intentelo mas tarde'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                        this.newUsuario()
                    }
                }
                if (this.existe=='no'){
                    // ingresar                    
                    const response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/usuarios/nuevo',parametros);
                    const datos=response.data;
                    //console.log(datos);
                    if (datos=='si'){ 
                        this.existe='si';               
                        this.Msg='Nuevo Usuario Existoso !!!'
                        this.msgbox=true;
                        this.color='success';
                        this.showSnack();
                    } 
                    if (datos=='error'){
                        this.Msg='Conexion Inestable, intentelo mas tarde'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                        //this.newUsuario()
                    }
                }
                // if (this.existe==''){
                //     this.Msg='Tiene que validar Usuario'
                //     this.msgbox=true;
                //     this.color='red'
                // }            
            } else {
                    this.Msg='Todos los datos son obligatorios'
                    this.msgbox=true;
                    this.color='info';
                    this.showSnack();
            }
            this.loading=false;
        },
        newUsuario(){
            this.loading=true;
            this.msgbox=false;
            this.usuario='';
            this.clsInput();
            this.disabledUsuario=false;
            this.filledUsuario=false;
            this.existe='';
            this.loading=false;
            this.restoDatos(true);
        },
        //locales NEW
        async newLocals(){
            this.dialogLocal=true;
            this.alertaLocal='';
            this.newLocal='';
        },
        CloseLocal() {
           if(this.newLocal!='' && this.alertaLocal=='si'){
                //buscar code de bodega
                const resultado = this.itembodega.find( dato => dato.name === this.newLocal );
                this.bodega=resultado.code;
            };
            this.newLocal='';
            this.alertaLocal='';
            this.disabledLocal=false;
            this.filledLocal=false;
            this.dialogLocal=false;
        },
        async addLocals(){
            try {
                if(this.newLocal!=''){
                    this.disabledLocal=true;
                    this.filledLocal=true;
                    var parametros={
                        'contrato': this.contrato,
                        'nombre': this.newLocal};
                    const response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/nueva',parametros);
                    const datos=response.data;
                    //console.log(datos);
                    if (datos=='si'){
                        await this.buscarBodegas();
                        //this.bodega=this.newLocal;
                        this.alertaLocal='si';
                    } else {
                        if (datos=='no'){
                            this.Msg='Local existe en el Historial';
                            this.msgbox=true;
                            this.color='red'
                            this.showSnack();
                        }
                    }
                } else {
                    this.alertaLocal='no';
                }                
            } catch (error) {
                console.log(error)
            }
        },
        async deleteLocal(item){
            try {
                //validar que la bodega no tenga stock
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/verify/'+this.contrato+'/'+item.id);
                const datos=response.data;
                if(datos[0].existe=='si'){
                    if (datos[0].Total!=0){
                        this.Msg='Bodega con Stock';
                        this.msgbox=true;
                        this.color='red'
                        this.showSnack();
                    } else {
                        //eliminar la bodega
                        const params={
                            'contrato':this.contrato,
                            'local':item.id,
                            'estado':'N'
                        }
                        const res = await axios.put('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/update/states/',params);
                        const dat=res.data;
                        if(dat=='si'){
                            await this.buscarBodegas();
                        }
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async resetPassword(){
            if(this.usuario!=null){
                if(this.usuario.trim()!=''){
                    if(this.existe=='si'){
                        try {
                            const params={
                                'contrato':this.contrato,
                                'usuario':this.usuario
                            }
                            const response = await axios.put('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/reset/usuario/',params);
                            const datos=response.data;
                            if (datos =='si'){
                                this.Msg='La contraseña fue reseteada con exito';
                                this.msgbox=true;
                                this.color='success'
                                this.showSnack();
                            }         
                        } catch (error) {
                            console.log(error)
                            this.Msg='No fue posible resetear la contraseña';
                            this.msgbox=true;
                            this.color='error'
                            this.showSnack();
                        }
                    } else {
                        this.Msg='El Usuario aun no ha sido registrado';
                        this.msgbox=true;
                        this.color='orange'
                        this.showSnack();
                    }
                } else {
                    this.Msg='Debe Ingresar Usuario para continuar';
                    this.msgbox=true;
                    this.color='error'
                    this.showSnack();
                }
            } else {
                this.Msg='Debe Ingresar Usuario para continuar';
                this.msgbox=true;
                this.color='error'
                this.showSnack();
            }
        }
    }
}
</script>