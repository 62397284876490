<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">
                <v-row>
                    <v-col cols="6" md="6">FLUJO</v-col>
                    <v-col cols="6" md="6">
                        <v-row class="mt-0 mr-2" justify="end">
                            <span class="text-caption"><v-chip color="white"><strong class="text-body-2">{{txtSucursal}}</strong></v-chip></span>
                            <!-- <span class="text-caption">Suc.:<strong class="text-body-2 ml-2">{{txtSucursal}}</strong></span> -->
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="6">
                            <!-- <v-chip outlined color="teal">
                                <v-icon class="ml-2">events</v-icon>
                                    {{date1}}
                            </v-chip>-->
                            <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip @click="OpenCalendar()" v-bind="attrs" v-on="on" outlined color="teal">
                                        <v-icon class="ml-2">events</v-icon>
                                            {{date1}}
                                    </v-chip>
                                </template>
                                <v-date-picker v-model="date1" @input="menu1 = false" no-title scrollable></v-date-picker>
                            </v-menu>                            
                            <v-btn class="ml-2" @click="buscarCaja()" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                        </v-col>
                        <v-col cols="10" md="3">
                            <v-alert class='text-caption' dense text border="left" color="red"><strong>Arqueo :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(arqueo))}}</div></strong></v-alert>
                        </v-col>
                        <v-col cols="2" md="3">
                            <v-btn @click="dialog=true" x-small fab dark color="indigo"><v-icon>add</v-icon></v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="mt-2">
                        <v-col cols="10" md="3">
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>Efectivo :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(efectivo))}}</div></strong></v-alert>
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>T.Debito :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(debito))}}</div></strong></v-alert> 
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>T.Credito :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(credito))}}</div></strong></v-alert>
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>Transferencia :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(transferencia))}}</div></strong></v-alert>
                        </v-col>
                        <v-col cols="10" md="3">
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>Cheques :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(cheque))}}</div></strong></v-alert>
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>Credito Cta.Cte :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(ctacte))}}</div></strong></v-alert>
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>Notas de Credito :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(nota))}}</div></strong></v-alert>
                            <v-alert class='text-caption' dense dark border="left" color="green"><strong>Total :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(total))}}</div></strong></v-alert>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-data-table :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size col-color" @page-count="pageCount = $event"></v-data-table>
                            <v-pagination v-model="page" :length="pageCount"></v-pagination>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
                <v-card-actions style="margin-top:-25px">
                    <v-spacer></v-spacer>
                    <v-btn class="ml-2 mr-2" @click="cerrarFlujo()" small color="teal" dark rounded><v-icon small>folder</v-icon><span class='text-btn'>Cerrar</span></v-btn>
            </v-card-actions>
        </v-card>
        <!-- DIALOGO ARQUEOS -->
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="600" persistent>
            <v-card>
            <v-toolbar color="primary" dark>ARQUEOS
                <v-spacer></v-spacer>
                <v-btn @click="dialog=false" small text fab><v-icon small>close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text class="mt-5">
                <v-row>
                    <v-col cols="7" md="4">
                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="monto" label="Monto" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="9" md="6">
                        <v-text-field class='text-caption' v-model="motivo" label="Motivo" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="3" md="2">
                        <v-btn @click="addArqueo()" small fab dark color="indigo"><v-icon>playlist_add</v-icon></v-btn>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-data-table dark dense :headers="head_arqueo" :items="items_arqueo" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1" @page-count="pageCount = $event">
                            <template v-slot:item.accion="{ item }">
                                <v-icon v-if="item.estado=='V'" small @click="deleteArqueo(item)">delete</v-icon>
                                <v-icon v-else small color="grey">do_not_disturb</v-icon>
                                <a v-if="item.estado=='V'" class="ml-2" target="_blank" :href="item.html" style="text-decoration: none"><v-icon small>print</v-icon></a>
                                <v-icon v-else class="ml-2" small color="grey">do_not_disturb</v-icon>
                            </template>
                        </v-data-table>
                    </v-col>   
                </v-row>
            </v-card-text>
            <v-card-actions>              
            </v-card-actions>
          </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 33px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Flujo', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            nSucursal:0,
            txtSucursal:'',
            menu1:false,
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 4,
            headers: [
                { text: 'Tipo DTE', align: 'start', sortable: true, value: 'tipo' },
                { text: 'Numero', align: 'start', sortable: false, value: 'numero' },
                { text: 'Forma Pago', align: 'center', sortable: false, value: 'pago' },
                { text: 'Referencia', align: 'center', sortable: false, value: 'referencia' },
                { text: 'Monto', align: 'center', sortable: false, value: 'monto' }
            ],
            items: [],
            efectivo:0,
            debito:0,
            credito:0,
            transferencia:0,
            cheque:0,
            ctacte:0,
            nota:0,
            total:0,
            dialog:false,
            arqueo:0,
            head_arqueo: [
                { text: 'ID', align: 'start', sortable: false, value: 'id' },
                { text: 'Monto', align: 'start', sortable: true, value: 'monto' },
                { text: 'Hora', align: 'start', sortable: false, value: 'hora' },
                { text: 'Motivo', align: 'start', sortable: false, value: 'motivo' },
                { text: 'Accion', align: 'start', sortable: false, value: 'accion' }
            ],
            items_arqueo: [],
            monto:'',
            ids:0,
            varArqueo:0,
            varEfectivo:0,
            varTotal:0,
            html:'',
            motivo:'',
            idLocal:0,
        }
    },
    mounted(){
        this.buscaLocal();
        //this.buscarSucursal()
    },
    methods:{
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        clsInput(){
            this.items=[];
            this.efectivo=0;
            this.debito=0;
            this.credito=0;
            this.transferencia=0;
            this.cheque=0;
            this.ctacte=0;
            this.nota=0;
            this.total=0;
            this.arqueo=0;
            this.items_arqueo=[];
            this.monto='';
            this.ids=0;
            this.varArqueo=0;
            this.varEfectivo=0;
            this.varTotal=0;
            this.html='';
            this.motivo='';
        },
        OpenCalendar(){
            this.menu1 = true;
            this.clsInput();
        },
        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        async buscaLocal(){
            try {
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/usuarios/'+this.contrato+'/'+this.usu);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.idLocal=datos[0].Bodega;
                    this.nSucursal=this.idLocal;
                    const sucu=await this.buscarSucursal_v2();
                    if(sucu!=''){
                        this.txtSucursal=sucu;
                    } else {
                        this.txtSucursal=this.nSucursal;
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async buscarSucursal_v2(){
            let res = '';
            try {
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    datos.forEach(element => {
                        if(element.ID==this.nSucursal){
                            res = element.Nombre
                        };
                    });
                }                
            } catch (error) {
                console.log(error)
            };
            return res
        },
        // async buscarSucursal(){
        //     const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/usuarios/'+this.contrato+'/'+this.usu);
        //     const datos=response.data;
        //     if (datos[0].existe=='si'){
        //         if (datos[0].Sucursal!=0){
        //             this.nSucursal=datos[0].Sucursal;
        //             const response1 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/sucursales/'+this.contrato);
        //             const datos1=response1.data;
        //             if (datos1[0].existe=='si'){
        //                 datos1.forEach(element => {
        //                     if(element.ID==this.nSucursal){
        //                         this.txtSucursal=element.Nombre                            
        //                     }
        //                 });
        //             } else {  
        //                 this.txtSucursal='Sin Sucursal';
        //                 this.nSucursal=0;
        //             }
        //         } else {
        //             this.txtSucursal='Sin Sucursal';
        //             this.nSucursal=0;
        //         }
        //     } else { 
        //         this.txtSucursal='Sin Sucursal';
        //         this.nSucursal=0;
        //     }
        // },
        async buscarCaja(){
            this.loading=true;
            this.clsInput();
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/cierre/buscar/'+this.contrato+'/'+this.date1+'/'+this.nSucursal);
            const datos=response.data;
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.items.push({
                        'tipo':element.Documento,
                        'numero':element.NumeroDte,
                        'pago':element.FormaPago,
                        'referencia':element.Referencia,
                        'monto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto))
                    })
                    // Agrupar totales
                    switch (element.FormaPago) {
                        case 'Efectivo':
                            this.efectivo=this.efectivo+parseFloat(element.Monto);
                            this.total=this.total+this.efectivo
                            break;
                        case 'T.Debito':
                            this.debito=this.debito+parseFloat(element.Monto);
                            this.total=this.total+this.debito
                            break;
                        case 'T.Credito':
                            this.credito=this.credito+parseFloat(element.Monto);
                            this.total=this.total+this.credito
                            break;
                        case 'Transferencia':
                            this.transferencia=this.transferencia+parseFloat(element.Monto);
                            this.total=this.total+this.transferencia
                            break;
                        case 'Cheque':
                            this.cheque=this.cheque+parseFloat(element.Monto);
                            this.total=this.total+this.cheque
                            break;
                        case 'Credito Cta.Cte.':
                            this.ctacte=this.ctacte+parseFloat(element.Monto);
                            this.total=this.total+this.ctacte
                            break;
                        case 'Nota de Credito':
                            this.nota=this.nota+parseFloat(element.Monto);
                            this.total=this.total+this.nota
                            break;
                        default:
                            break;
                    }
                    //asignar totales para arqueo
                    this.varEfectivo=this.efectivo;
                    this.varTotal=this.total;
                });
            }
            await this.arrayArqueo();
            this.loading=false;
        },
        async arrayArqueo(){
            const response0 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/cierre/arqueo/buscar/'+this.contrato+'/'+this.date1+'/'+this.nSucursal);
            const datos0=response0.data;
            this.items_arqueo=[];
            this.arqueo=0;
            this.efectivo=this.varEfectivo;
            this.total=this.varTotal;
            if (datos0[0].existe=='si'){
                datos0.forEach(element => {
                    this.html='http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/prt/arqueo/'+this.contrato+'/'+element.ID+'/'+this.usu;
                    this.items_arqueo.push({
                        'id':element.ID,
                        'monto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto)),
                        'hora':element.Hora,
                        'motivo':element.Motivo,
                        'valor':element.Monto,
                        'estado':element.Estado,
                        'html':this.html               
                    })
                    this.arqueo=this.arqueo+parseFloat(element.Monto);
                    this.efectivo=this.efectivo-parseFloat(element.Monto);
                    this.total=this.total-parseFloat(element.Monto);             
                });
            }
        },
        showTime(){
            let myDate = new Date();
            let hours = myDate.getHours();
            let minutes = myDate.getMinutes();
            let seconds = myDate.getSeconds();
            if (hours < 10) hours = 0 + hours;
            if (minutes < 10) minutes = "0" + minutes;
            if (seconds < 10) seconds = "0" + seconds;
            let hora=hours+ ":" +minutes+ ":" +seconds;
            return hora;
        },
        async addArqueo(){
            this.loading=true;
            if(this.monto.trim()!=''){
                const ahora=this.showTime();
                const params={
                    'contrato':this.contrato,
                    'monto': this.monto,
                    'hora': ahora,
                    'motivo':this.motivo,
                    'fecha':this.date1,
                    'sucursal':this.nSucursal,
                    'estado':'V'
                }
                const response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/cierre/arqueo/nuevo',params);
                const datos=response.data;
                if (datos=='si'){ 
                    await this.arrayArqueo();
                }
                this.monto='';
                this.motivo='';            
            }
            this.loading=false;
        },
        async deleteArqueo(array){
            const response = await axios.delete('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/cierre/arqueo/delete/'+this.contrato+'/'+array.id);
            const datos=response.data;
            if (datos!='error'){
                await this.arrayArqueo();
            }   
        },
        async cerrarFlujo(){
            this.loading=true;
            const params={'estado':'N'};
            const response = await axios.put('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/pago/actualiza/'+this.contrato+'/'+this.nSucursal+'/'+this.date1,params);
            const datos=response.data;
            if (datos=='si'){
                this.Msg='Flujo Cerrado'
                this.msgbox=true;
                this.color='success';
                this.showSnack();
            }
            this.loading=false;
        }
    }
}
</script>