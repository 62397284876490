<template>
  <div>
    <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">Productos</h2> <!-- Full Screen -->
    <h3 class="mt-1 hidden-sm-and-up grey--text">Productos</h3> <!-- Mobile Screen -->
    <div class="mt-5">
      <v-bottom-navigation :value="value" color="success">
        <v-btn @click="OpenComponent(0)" :disabled="comp0">      
          <span>Crear</span>
          <v-icon>{{CreateIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(1)" :disabled="comp1">
          <span>Web</span>
          <v-icon>{{WebIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(2)" :disabled="comp2">
          <span>Precios</span>
          <v-icon>{{PriceIcon}}</v-icon>
        </v-btn>
      </v-bottom-navigation>
      <div class="mt-2">

          <component v-bind:is="componente" :usu="usu" :contrato="contrato"></component>

      </div>
    </div>
    <dialogo :loading="loading"  />
  </div>
</template>

<script>
import axios from 'axios';
import CrearProducto from '@/components/CrearProducto.vue';
//import WebProducto from '@/components/WebProducto.vue';
import WebProducto from '@/components/IntegraWeb.vue';
import Precios from '@/components/Precios.vue';
import dialogo from '@/components/dialogo.vue';
export default {
  name: 'Productos',
  components: { CrearProducto, WebProducto ,dialogo, Precios},
  props: ['usu','contrato'],
  data(){
    return{
      loading:false,
      value:null,
      componente:'',
      IconSelect:'check_circle',
      CreateIcon:'create',
      WebIcon:'public',
      PriceIcon:'monetization_on',
      CreateIconDefault:'create',
      WebIconDefault:'public',
      PriceIconDefault:'monetization_on',
      comp0:true,
      comp1:true,
      comp2:true,
    }
  },
  async mounted(){
    await this.entrada();
  },
  methods:{
    async entrada(){
      this.loading=true;
      const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
      const datos=response.data;
      //console.log(datos[0])
      if (datos[0].existe=='si'){
        if(datos[0].Permiso_2_1!=0) {
          this.comp0=false;
        };
        if(datos[0].Permiso_2_2!=0) {
          this.comp1=false;
        };
        if(datos[0].Permiso_2_3!=0) {
          this.comp2=false;
        };
      } 
      this.moduloActivo();
    },
    moduloActivo(){
      if (this.comp0!=true){
          this.componente='CrearProducto';
          this.CreateIcon=this.IconSelect;
          this.WebIcon=this.WebIconDefault;
          this.PriceIcon=this.PriceIconDefault;
          this.value=0;
      } else {
          if (this.comp1!=true){
            this.componente='WebProducto';
            this.CreateIcon=this.CreateIconDefault;
            this.WebIcon=this.IconSelect;
            this.PriceIcon=this.PriceIconDefault;
            this.value=1;
          } else {
              if(this.comp2!=true){
                this.componente='Precios';
                this.CreateIcon=this.CreateIconDefault;
                this.WebIcon=this.WebIconDefault;
                this.PriceIcon=this.IconSelect;
                this.value=2;
              } else{
                this.CreateIcon=this.CreateIconDefault;
                this.WebIcon=this.WebIconDefault;
                this.PriceIcon=this.PriceIconDefault;
              }
            }
        }
        this.loading=false;
    },
    OpenComponent(valor){
      let vComponente;
      switch (valor) {
        case 0:
          vComponente='CrearProducto';
          this.CreateIcon=this.IconSelect;
          this.WebIcon=this.WebIconDefault;
          this.PriceIcon=this.PriceIconDefault;
          break;
        case 1:
          vComponente='WebProducto';
          this.CreateIcon=this.CreateIconDefault;
          this.WebIcon=this.IconSelect;
          this.PriceIcon=this.PriceIconDefault;
          break;
        case 2:
          vComponente='Precios';
          this.CreateIcon=this.CreateIconDefault;
          this.WebIcon=this.WebIconDefault;
          this.PriceIcon=this.IconSelect;
          break;
      }
      this.componente=vComponente;
    }
  }
}
</script>