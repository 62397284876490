<template>
    <div>
        <h3>Agentes del Negocio</h3>
        <v-divider></v-divider>
        <div class="mt-2">
        <v-data-table :headers="headerSetup" :items="itemSetup" class="elevation-1 text-size col-color" hide-default-footer>
          <template v-slot:item.tPromedio="{ item }">
              <v-chip small :color="getColor(item.tPromedio)" dark>
                  {{ item.tPromedio }}%
              </v-chip>
          </template>
        </v-data-table>
        </div>
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 33px !important;
    /* background-color: lightyellow; */
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import axios from 'axios';
export default {
  name: 'DashSetup',
  props: ['usu','contrato'],
  data(){
    return {
      //contrato:'123',
      headerSetup:[
        {text: 'Agentes',align: 'left',sortable: true, value: 'tAgente', class:['font-size:7pt']},
        {text: 'Total',align: 'center',sortable: false, value: 'tTotal'},
        {text: 'Activos',align: 'center',sortable: false, value: 'tActivos'},
        {text: 'Pasivos',align: 'center',sortable: false, value: 'tPasivos'},
        {text: 'Promedio Activos',align: 'center',sortable: false, value: 'tPromedio'}
      ],
      itemSetup: [],
    }
  },
  async mounted(){
    const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/dash/setup/'+this.contrato);
    const datos=response.data;
    this.itemSetup=[];
    if (datos[0].existe=='si'){
      datos.forEach(element => {
        this.itemSetup.push({tAgente:element.Agente,tTotal:element.Total,tActivos:element.Activos,tPasivos:element.Pasivos,tPromedio:element.Promedio})        
      });
    }     
  },
  methods: {
    getColor (tPromedio) {
      if (parseFloat(tPromedio) < 25) return 'red'
      else if (parseFloat(tPromedio) < 50) return 'orange'
      else return 'green'
    },
  },
}
</script>