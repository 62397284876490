<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">
                <v-row>
                    <v-col cols="6" md="6">DEVOLUCIÓN</v-col>
                    <v-col cols="6" md="6">
                        <v-row class="mt-0 mr-2" justify="end">
                            <span class="text-caption"><v-chip color="white"><strong class="text-body-2">{{txtSucursal}}</strong></v-chip></span>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="3" class="mt-n2">
                                <v-radio-group v-model="docDTE" row>
                                    <v-radio @click="newVenta()" label="Boleta" color="success" value="22"></v-radio>
                                    <v-radio @click="newVenta()" label="Factura" color="success" value="5"></v-radio>
                                </v-radio-group>
                            </v-col>                            
                            <v-col cols="8" md="2">
                                <v-text-field class='text-caption' v-model="orden" label="Numero Venta" :disabled="disabledOrden" :filled="filledOrden" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1">
                                <v-btn @click="verDTE(orden)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field class='text-caption' v-model="rut" label="Rut Cliente" dense outlined disabled filled></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field class='text-caption' v-model="razon" label="Nombre" dense outlined disabled filled></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1">
                                <v-btn v-if="ntipoDte==22" class="mt-1" @click="dialog=true" small color="teal" dark rounded><v-icon small>streetview</v-icon></v-btn>
                                <v-btn v-else class="mt-1" small color="grey" dark rounded><v-icon small>streetview</v-icon></v-btn>
                            </v-col>
                            <!-- <v-col cols="12" md="2">
                                <v-text-field class='text-caption' v-model="ndev" label="Orden" dense outlined filled disabled></v-text-field>
                            </v-col> -->
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12" md="9">
                                <v-data-table :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size col-color" @page-count="pageCount = $event">
                                    <template v-slot:item.tcantidad="{ item }">
                                        <span small>{{ item.tcantidad }}</span>
                                        <v-icon class="ml-1 red--text" @click="menos(item)">arrow_drop_down</v-icon>
                                    </template>
                                    <template v-slot:item.accion="{ item }">
                                        <v-icon small class="red--text" @click="deleteItem(item)">delete</v-icon>
                                    </template>
                                </v-data-table>
                                <v-pagination v-model="page" :length="pageCount"></v-pagination>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-alert class='text-caption' dense text border="left" color="grey">
                                    <div class="float-left">
                                        Neto
                                    </div>
                                    <div class="float-right">
                                        ${{new Intl.NumberFormat("de-DE").format(parseFloat(neto))}}
                                    </div>                                             
                                </v-alert>
                                <v-alert class='mt-n2 text-caption' dense text border="left" color="grey">
                                    <div class="float-left">
                                        Iva ({{impuesto}}%)
                                    </div>
                                    <div class="float-right">
                                        ${{new Intl.NumberFormat("de-DE").format(parseFloat(iva))}}
                                    </div>                                             
                                </v-alert>
                                <v-alert style="height:40px;" class='mt-n2 text-body-2' dense text border="left" color="red">
                                    <div class="mt-1 float-left">
                                        Total
                                    </div>
                                    <div class="mt-1 float-right">
                                        ${{new Intl.NumberFormat("de-DE").format(parseFloat(total))}}
                                    </div>                                             
                                </v-alert>
                                <!-- <v-alert class='text-caption' dense text border="left" color="grey">Neto ($):{{neto.toLocaleString()}}</v-alert> 
                                <v-alert class='text-caption' dense text border="left" color="grey">Iva ({{impuesto}}%): {{iva}}</v-alert>
                                <v-alert class='text-caption' dense text border="left" color="red">Total ($): {{total.toLocaleString()}}</v-alert> -->
                            </v-col>
                            <v-col cols="12" md="1">
                                <v-text-field class='text-caption' v-model="totaldev" label="NC" disabled filled dense></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-select class='text-caption' v-model="dte" :items="itemDTE" label="Selecciona DTE" item-text="doc" item-value="id"></v-select>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-select class='ml-2 text-caption' v-model="pago" :items="itemPAGO" label="Forma Pago" item-text="forma" item-value="id"></v-select>
                            </v-col>
                              <v-col cols="12" md="3">
                                <v-text-field class='text-caption' v-model="envio" label="Envio Digital" dense filled></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <div class="mt-5 text-right">
                                    <v-btn @click="newVenta()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                                    <!-- <v-btn v-if="btnGrabar===true" @click="outDTE(dte,pago)" class="ml-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn> -->
                                    <v-btn v-if="btnGrabar===true" @click="enviarDTE(dte,pago)" class="ml-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Enviar DTE</span></v-btn>
                                    <v-btn v-else class="ml-2" small color="grey" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Enviar DTE</span></v-btn>
                                    <a v-if="btnPrt===true" target="_blank" :href="html" class="ml-2"><v-icon color="teal">print</v-icon></a>
                                    <a v-else href="javascript:void(0)" class="ml-2"><v-icon class="grey--text">print</v-icon></a> 
                                    <!-- <a  class="ml-2" v-if="descargaCSV===false" href="javascript:void(0)" @click="fileCSV()"><img valign="middle" src="@/assets/csv.png" width="16px" height="20px"/></a>
                                    <a  class="ml-2" v-else :href="rutaCSV"><v-icon color="teal">cloud_download</v-icon></a> -->
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
            </v-card-text>
            <v-card-actions style="margin-top:-35px">
            </v-card-actions>
        </v-card>
        <!-- Dialogo Cliente Boleta -->
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="700" persistent>
            <form>
            <v-card>
            <v-toolbar color="primary" dark>Cliente Devolución Boleta</v-toolbar>
            <v-card-text class="mt-5">
                <v-row>                    
                     <v-col cols="12" md="4">
                        <v-text-field class='text-caption' v-model="dev_rut" label="Rut" outlined dense></v-text-field>
                     </v-col>    
                     <v-col cols="12" md="4">
                        <v-text-field class='text-caption' v-model="dev_nombre" label="Nombre" outlined dense></v-text-field>
                     </v-col>    
                     <v-col cols="12" md="4">
                        <v-text-field class='text-caption' v-model="dev_apellido" label="Apellido" outlined dense></v-text-field>
                     </v-col>    
                     <v-col cols="12" md="6">
                        <v-text-field class='text-caption' v-model="dev_direccion" label="Direccion" outlined dense></v-text-field>
                     </v-col>    
                     <v-col cols="12" md="3">
                        <v-text-field class='text-caption' v-model="dev_comuna" label="Comuna" outlined dense></v-text-field>
                     </v-col>    
                     <v-col cols="12" md="3">
                        <v-text-field class='text-caption' v-model="dev_ciudad" label="Ciudad" outlined dense></v-text-field>
                     </v-col>    
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="salirDialog()" color="indigo">Salir</v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDialog()" color="indigo">Enviar</v-btn>
            </v-card-actions>
          </v-card>
          </form>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 25px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Devolucion', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            //contrato:'123',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            disabledOrden:false,
            filledOrden:false,
            disabledDatos:true,
            btnGrabar:false,
            btnPrt:false,
            html:'',
            orden:'',
            ndev:'0',
            totaldev:'0',
            entidad:'',
            rut:'',
            razon:'',
            direccion:'',
            comuna:'',
            ciudad:'',
            giro:'',
            fecha:'',
            hoy:'',
            neto:0,
            impuesto:'',
            iva:0,
            total:0,
            dte:'',
            pago:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 4,
            headers: [
                { text: 'Codigo', align: 'start', sortable: true, value: 'codigo' },
                { text: 'Producto', align: 'start', sortable: false, value: 'producto' },
                { text: 'Precio', align: 'center', sortable: false, value: 'precio' },
                { text: 'Cantidad', align: 'center', sortable: false, value: 'tcantidad' },
                { text: 'Medida', align: 'center', sortable: false, value: 'medida' },
                // { text: 'Bodega', align: 'center', sortable: false, value: 'bodega' },
                { text: '', align: 'center', sortable: false, value: 'accion' }
            ],
            items: [],
            itemDTE:[
                {id:'2',doc:'Nota Credito'},
            ],
            itemPAGO:[],
            // itemPAGO:[
            //     {id:'1',forma:'EFECTIVO'},
            //     {id:'8',forma:'TRANSFERENCIA'},
            //     {id:'4',forma:'CREDITO'}
            // ],
            OutID:0,  
            descargaCSV:false,
            rutaCSV:'',
            envio:'',
            nSucursal:0,
            txtSucursal:'',
            ntipoDte:0,
            dialog:false,
            dev_rut:'',
            dev_nombre:'',
            dev_apellido:'',
            dev_direccion:'',
            dev_comuna:'',
            dev_ciudad:'',
            docDTE:'5',
            idLocal:0,
        }
    },
    async mounted(){
        await this.newVenta();
        // this.buscarSucursal()
        await this.buscaLocal();
    },
    methods:{
        async cargaPagos(xRut){
            try {
                var auth='NO';
                const res = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/clientes/'+this.contrato+'/'+ xRut);
                const dat=res.data;
                if (dat[0].existe=='si'){ auth=dat[0].Credito };            
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/DTE/forma/pago/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.itemPAGO=[];
                    datos.forEach(element => {
                        if(element.CodigoBsale=='4'){
                            if(auth=='SI'){
                                this.itemPAGO.push({
                                    'id':element.CodigoBsale,
                                    'forma':element.Descripcion
                                })
                            }
                        } else {
                            if(element.CodigoBsale=='1' || element.CodigoBsale=='8'){
                                this.itemPAGO.push({
                                    'id':element.CodigoBsale,
                                    'forma':element.Descripcion
                                })
                            }
                        }
                    });
                }                
            } catch (error) {
                console.log(error)
            }
        },
        async buscaLocal(){
            try {
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/usuarios/'+this.contrato+'/'+this.usu);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.idLocal=datos[0].Bodega;
                    this.nSucursal=this.idLocal;
                    const sucu=await this.buscarSucursal_v2();
                    if(sucu!=''){
                        this.txtSucursal=sucu;
                    } else {
                        this.txtSucursal=this.nSucursal;
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async buscarSucursal_v2(){
            let res = '';
            try {
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    datos.forEach(element => {
                        if(element.ID==this.nSucursal){
                            res = element.Nombre
                        };
                    });
                }                
            } catch (error) {
                console.log(error)
            };
            return res
        },
        salirDialog(){
            this.rut='';
            this.dev_rut='';
            this.dev_nombre='';
            this.dev_apellido='';
            this.dev_direccion='';
            this.dev_comuna='';
            this.dev_ciudad='';
            this.dialog=false;
        },
        closeDialog(){            
            if(this.dev_rut.trim()!='' &&
                this.dev_nombre.trim()!='' &&
                this.dev_apellido.trim()!='' &&
                this.dev_direccion.trim()!='' &&
                this.dev_comuna.trim()!='' &&
                this.dev_ciudad.trim()!=''){
                let ruts=this.validaRut(this.dev_rut);
                if (ruts){
                    this.rut=this.dev_rut;
                    this.razon=this.dev_nombre+' '+ this.dev_apellido;
                    this.dialog=false;                    
                } else {
                    this.Msg='Rut no Valido'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } else {
                this.Msg='Datos Obligatorios'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        validaRut(rutCompleto){
            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
			    return false;
		    var tmp 	= rutCompleto.split('-');
		    var digv	= tmp[1]; 
		    var ruti 	= tmp[0];
		    if ( digv == 'K' ) digv = 'k' ;
		    return (this.dv(ruti) == digv );
        },
        dv(T){
            var M=0,S=1;
    		for(;T;T=Math.floor(T/10))
	    		S=(S+T%10*(9-M++%6))%11;
		    return S?S-1:'k';
        },
        // async buscarSucursal(){
        //     const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/usuarios/'+this.contrato+'/'+this.usu);
        //     const datos=response.data;
        //     if (datos[0].existe=='si'){
        //         if (datos[0].Sucursal!=0){
        //             this.nSucursal=datos[0].Sucursal;
        //             const response1 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/sucursales/'+this.contrato);
        //             const datos1=response1.data;
        //             if (datos1[0].existe=='si'){
        //                 datos1.forEach(element => {
        //                     if(element.ID==this.nSucursal){
        //                         this.txtSucursal=element.Nombre                            
        //                     }
        //                 });
        //             } else {  
        //                 this.txtSucursal='Sin Sucursal';
        //                 this.nSucursal=0;
        //             }
        //         } else {
        //             this.txtSucursal='Sin Sucursal';
        //             this.nSucursal=0;
        //         }
        //     } else { 
        //         this.txtSucursal='Sin Sucursal';
        //         this.nSucursal=0;
        //     }
        // },
        async fileCSV(){
            if (this.btnPrt===true){
                this.loading=true;
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/fileCsv/dev/'+this.contrato+'/'+this.OutID+'/'+this.entidad);
                const datos=response.data;
                //console.log(datos);
                if (datos=='si'){
                    this.descargaCSV=true;
                    this.rutaCSV='http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+this.OutID+'.csv';
                }
                this.loading=false;
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        clsInput(){
            this.entidad='';
            this.ndev='0';
            this.totaldev='0';
            this.rut='';
            this.razon='';
            this.direccion='';
            this.comuna='';
            this.ciudad='';
            this.giro='';
            this.fecha='';
            this.hoy='';
            this.neto=0;
            this.iva=0;
            this.total=0;
            this.impuesto='';
            this.dte='';
            this.pago='';
            this.items=[];
            this.btnPrt=false;
            this.html='';
            this.OutID=0;
            this.descargaCSV=false;
            this.rutaCSV='';  
            this.envio='';
            this.disabledDatos=true;
            this.ntipoDte=0;
            this.dev_rut='';
            this.dev_nombre='';
            this.dev_apellido='';
            this.dev_direccion='';
            this.dev_comuna='';
            this.dev_ciudad='';
        },
        newVenta(){
            this.orden='';
            this.disabledOrden=false;
            this.filledOrden=false;
            this.btnGrabar=false;
            this.clsInput();
            if(this.docDTE=='22'){
                this.cargaPagos('1-9')
            }
        },
        async verDTE(nOrden){
            if (this.orden!=''){
                this.clsInput();
                this.loading=true;
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/DTE/dev/'+this.contrato+'/'+nOrden+'/'+this.docDTE);
                const datos=response.data;
                //console.log(datos);
                if (datos[0].existe=='si'){
                    this.disabledDatos=false;  
                    this.disabledOrden=true;
                    this.filledOrden=true;
                    this.btnGrabar=true;
                    this.ndev = datos[0].Orden;
                    this.totaldev = datos[0].Devolucion;
                    this.entidad=datos[0].Entidad;
                    this.rut=datos[0].Rut;
                    this.razon=datos[0].Nombre;
                    this.direccion=datos[0].Direccion;
                    this.comuna=datos[0].Comuna;
                    this.ciudad=datos[0].Ciudad;
                    this.giro=datos[0].Observacion;
                    this.fecha=datos[0].Hoy;
                    this.hoy=datos[0].Hoy;
                    this.impuesto=datos[0].Iva;
                    this.cargaPagos(this.rut);
                    this.ntipoDte=parseFloat(this.docDTE); //datos[0].TipoDte;
                    this.items=[];
                    let nNeto=0;
                    let nTotal=0;
                    for (let index = 0; index < datos.length; index++) {
                        const element = datos[index];
                        //calcular precio unitario
                        let imp=1+(this.impuesto/100);
                        let unidad=element.Precio/imp;
                        const tMedida=await this.searchMedida(element.Sku);
                        this.items.push({
                            'codigo':element.Sku,
                            'producto':element.Producto,
                            'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                            'unidad':unidad,
                            'tcantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad).toFixed(3)),
                            'cantidad':element.Cantidad,
                            'medida':tMedida,
                            'bodega':element.Bodega,
                            'precioitem':element.Precio
                        });
                        nNeto=nNeto+(unidad*parseFloat(element.Cantidad));
                        nTotal=nTotal+parseFloat(element.Total)       
                    };
                    let nIva=(nNeto*parseFloat(datos[0].Iva))/100;
                    this.neto=parseFloat(nNeto).toFixed(2);
                    this.iva=parseFloat(nIva).toFixed(2);
                    this.total=nTotal;
                    if (this.ntipoDte==22){
                        var sigla=this.contrato.substring(0,3);
                        if (sigla='INV') {
                          this.rut='';
                          this.dev_rut='';
                        }
                    }
                } else {
                    this.Msg='Orden no Existe o con DTE'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
                this.loading=false;
            }
        },
        async searchMedida(mSku){
            try {
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/unidad/medida/'+ this.contrato +'/'+ mSku);
                const datos=response.data;
                if (datos[0].existe=='si'){ 
                    return datos[0].Medida;
                } else { return 'Sin Medida' }
            } catch (error) {
                return 'Sin Medida'
            }
        },
        reCalcular(newTotal){
                let imp=1+(this.impuesto/100);
                let varNeto=newTotal/imp;
                let varIva=(varNeto*this.impuesto)/100;
                this.neto=parseFloat(varNeto).toFixed(2);
                this.iva=parseFloat(varIva).toFixed(2);
                this.total=newTotal;
        },
        deleteItem(array){
            // this.items = this.items.filter(function(dat) {
            //     return dat.codigo !== array.codigo; 
            // });
            let varTotal=parseFloat(this.total)-(parseFloat(array.precioitem)*parseFloat(array.cantidad));
            array.cantidad='0';
            array.tcantidad='0';
            this.reCalcular(varTotal);
        },
        menos(array){
            if (array.cantidad>1){
                array.cantidad=parseFloat(array.cantidad)-1
                array.tcantidad=new Intl.NumberFormat("de-DE").format(parseFloat(array.cantidad).toFixed(3));
                let varTotal=parseFloat(this.total)-parseFloat(array.precioitem);
                this.reCalcular(varTotal);
            } else {
                if (array.cantidad>0){
                    // array.cantidad=parseFloat(array.cantidad)-1
                    // array.tcantidad=new Intl.NumberFormat("de-DE").format(parseFloat(array.cantidad).toFixed(3));
                    let varTotal=parseFloat(this.total)-(parseFloat(array.precioitem)*parseFloat(array.cantidad));
                    array.cantidad='0';
                    array.tcantidad='0';
                    this.reCalcular(varTotal);
                }
            }
        },
        // async outDTE(selectDTE,selectPago){
        //     if (parseFloat(this.total)!=0){
        //         if (selectDTE!=0 && selectPago!=0){
        //             this.loading=true;
        //              this.btnGrabar=false;
        //             //obtener ID nuevo del dte
        //             const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/DTE/id/new/'+this.contrato);
        //             const res = response.data;
        //             if (res[0].existe=='si'){ 
        //                 this.OutID=parseFloat(res[0].ID)+1; 
        //                 this.html='http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/prt/dev/'+this.contrato+'/'+this.OutID+'/'+this.entidad;            
        //                 const paramsDTE={
        //                     'contrato':this.contrato,
        //                     'numero':this.OutID,
        //                     'tipoDte':selectDTE,
        //                     'tipoPago':selectPago,
        //                     'neto':this.neto,
        //                     'iva':this.iva,
        //                     'total':this.total,
        //                     'fecha':this.hoy,
        //                     'orden':this.ndev,
        //                     'rutaPDF':this.html,
        //                     'rutaXML':this.html,
        //                     'usuario':this.usu
        //                 };     
        //                 await this.saveDTE(paramsDTE); 
        //             } else {  
        //                 this.OutID=0 
        //                 this.Msg='Sin ID Devolución'
        //                 this.msgbox=true;
        //                 this.color='red';
        //                 this.showSnack();
        //             };
        //         } else {
        //             this.Msg='Seleccione DTE y Pago'
        //             this.msgbox=true;
        //             this.color='red';
        //             this.showSnack();
        //         }
        //     } else {
        //     }
        //     this.loading=false;
        // },
        async enviarDTE(selectDTE,selectPago){
            // validar tipo de sistema
            var sigla=this.contrato.substring(0,3);
            //console.log(sigla);
            if (sigla=='INV') {
                await this.preDTE(selectDTE,selectPago);
            } else {
                if (sigla=='NDV'){
                    await this.outDTE(selectDTE,selectPago);
                } else {
                    this.Msg='Error de Contrato'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            }
        },
        async preDTE(selectDTE,selectPago){
            if(this.rut.trim()!=''){
                await this.inDTE(selectDTE,selectPago);
            } else {
                this.Msg='Ingrese Cliente'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        async outDTE(selectDTE,selectPago){
            console.log(this.items);
            if (parseFloat(this.total)!=0){
                if (selectDTE!=0 && selectPago!=0){
                    this.loading=true;
                    this.btnGrabar=false;
                    this.OutID=0;
                    //obtener ID nuevo del dte
                    const params={
                        'contrato':this.contrato,
                        'orden':this.orden,
                        'estado':'V',
                        'usuario':this.usu
                    };
                    try {
                        const response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/DTE/id/new/v2/',params);
                        const res = response.data;
                        if (res=='si'){
                            const response1 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/DTE/id/new/v2/'+this.contrato+'/'+this.orden+'/'+this.usu);
                            const res1 = response1.data;
                            if (res1[0].existe=='si'){
                                this.OutID=parseFloat(res1[0].Folio); 
                            }                            
                        };
                        if (this.OutID!=0){
                            //console.log(this.OutID);
                            //confirmar que no existe en DTE
                            const response2 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/DTE/one/'+this.contrato+'/'+this.OutID);
                            const res2 = response2.data;
                            if (res2[0].existe=='no'){ 
                                this.html='http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/prt/dev/v5/'+this.contrato+'/'+this.OutID+'/'+this.entidad;
                                const paramsDTE={
                                    'contrato':this.contrato,
                                    'numero':this.OutID,
                                    'tipoDte':selectDTE,
                                    'tipoPago':selectPago,
                                    'neto':this.neto,
                                    'iva':this.iva,
                                    'total':this.total,
                                    'fecha':this.hoy,
                                    'vencimiento':this.hoy,
                                    'orden':this.ndev,
                                    'rutaPDF':this.html,
                                    'rutaXML':this.html,
                                    'usuario':this.usu,
                                    'sucursal':this.nSucursal
                                };     
                                await this.saveDTE(paramsDTE);
                            } else {
                                this.Msg='No Existen Folios'
                                this.msgbox=true;
                                this.color='red';
                                this.showSnack();
                            }
                        } else {
                            this.Msg='Folio No Asignado'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    this.Msg='Seleccione DTE y Pago'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } else {
                this.Msg='Sin Productos'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
            this.loading=false;
        },
        async inDTE(selectDTE,selectPago){
            if(this.nSucursal!=0){
                if (this.items.length!=0 && parseFloat(this.total)!=0 && this.orden!=''){
                    if (selectDTE!=0 && selectPago!=0){
                        this.loading=true;
                        // obtenemos el token del contrato
                        let valToken='';
                        const resToken = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/token/access/'+this.contrato);
                        const respuesta = resToken.data;
                        if (respuesta[0].existe=='si'){ 
                            valToken=respuesta[0].Token;
                            // fecha cotizacion formato Bsale
                            let intFecha=Date.parse(this.fecha);
                            let strFecha=intFecha.toString();
                            strFecha=strFecha.substring(0,10);
                            const numFecha=parseFloat(strFecha);
                            // fecha actual formato Bsale
                            let intHoy=Date.parse(this.hoy);
                            let strHoy=intHoy.toString();
                            strHoy=strHoy.substring(0,10);
                            const numHoy=parseFloat(strHoy);
                            //api bsale para rescatar id venta
                            let nID_Venta=0;
                            try {
                                let responseV;
                                // Factura
                                if(this.docDTE==5){ 
                                    responseV = await axios.get('https://api.bsale.cl/v1/documents.json?number='+this.orden+'&codesii=33', {headers : {"Accept":"application/json","content-type":"application/json","access_token":valToken}});
                                };
                                // Boleta 
                                if(this.docDTE==22){ 
                                    responseV = await axios.get('https://api.bsale.cl/v1/documents.json?number='+this.orden+'&codesii=39', {headers : {"Accept":"application/json","content-type":"application/json","access_token":valToken}});
                                };
                                const resVenta = responseV.data;
                                //console.log(resVenta);
                                if (resVenta.length!=0){
                                    nID_Venta=resVenta.items[0].id;
                                }
                            } catch (error) {
                                console.log(error);
                            };
                            //api bsale para rescatar id detalle de venta
                            let nID_Details=0;
                            let pDetalle=[];
                            try {
                                const responseD = await axios.get('https://api.bsale.cl/v1/documents/'+nID_Venta+'/details.json', {headers : {"Accept":"application/json","content-type":"application/json","access_token":valToken}});
                                const resDetails = responseD.data;
                                //console.log(resDetails);
                                if (resDetails.length!=0){
                                    //recorrer los items de la venta
                                    this.items.forEach(elementos => {
                                        // recorrer los detalles del documento
                                        resDetails.items.forEach(element => {
                                            // llena el details de Bsale
                                            if(element.variant.description==elementos.producto && parseFloat(elementos.cantidad)>0){
                                                pDetalle.push({
                                                    "documentDetailId": parseFloat(element.id),
                                                    "quantity": parseFloat(elementos.cantidad).toFixed(4),
                                                    "unitValue": parseFloat(elementos.unidad).toFixed(4),                            
                                                })        
                                                nID_Details=1;
                                            }                                
                                        });
                                    });
                                }                            
                            } catch (error) {
                                console.log(error);
                            };
                            if (nID_Venta!=0 && nID_Details!=0){
                                // creamos cliente devolucion Bsale
                                let pCliente;
                                if(this.ntipoDte!=22){ // factura
                                    pCliente={
                                        "code": this.rut,
                                        "city": this.ciudad,
                                        "company": this.razon,
                                        "municipality": this.comuna,
                                        "activity": this.giro,
                                        "address": this.direccion
                                    };
                                } else { // boleta
                                    pCliente={
                                        "code": this.dev_rut,
                                        "city": this.dev_ciudad,
                                        "municipality": this.dev_comuna,
                                        "address": this.dev_direccion,
                                        "firstName":this.dev_nombre,
                                        "lastName": this.dev_apellido
                                    }; 
                                };
                                // creamos parametros para generar devolucion
                                const emitDTE=
                                    {
                                        "documentTypeId": parseFloat(selectDTE),
                                        "officeId": 1,
                                        "emissionDate": numHoy,
                                        "expirationDate": numHoy,
                                        "referenceDocumentId": nID_Venta,
                                        "motive": "Devolucion de productos",
                                        "priceAdjustment": 1,
                                        "editTexts": 0,
                                        "type": 0,
                                        "client": pCliente,
                                        "details": pDetalle,
                                        "declareSii": 1
                                    }
                                // consumir api Bsale para crear DTE
                                let resDTE =[];
                                //console.log(emitDTE);
                                try {
                                    const response = await axios.post('https://api.bsale.cl/v1/returns.json', emitDTE , {headers : {"Accept":"application/json","content-type":"application/json","access_token":valToken}});
                                    resDTE = response.data;
                                } catch (error) {
                                    console.log(error);                        
                                }
                                if (resDTE.length!=0){
                                    //console.log(resDTE);
                                    const NotaCredito=resDTE.credit_note.id;
                                    // consumir api para rescatar los datos de la NC
                                    let resNC =[];
                                    const responseNC = await axios.get('https://api.bsale.cl/v1/documents/'+NotaCredito+'.json', {headers : {"Accept":"application/json","content-type":"application/json","access_token":valToken}});
                                    resNC = responseNC.data;
                                    if (resNC.length!=0){
                                        this.envioDTE(this.envio,resNC.urlPdf,resNC.urlXml);
                                        this.html=resNC.urlPdf;
                                        this.btnPrt=true;
                                        this.btnGrabar=false;
                                        this.OutID=0;
                                        this.OutID=resNC.number;                      
                                        const paramsDTE={
                                            'contrato':this.contrato,
                                            'numero':resNC.number,
                                            'tipoDte':selectDTE,
                                            'tipoPago':selectPago,
                                            'neto':this.neto,
                                            'iva':this.iva,
                                            'total':this.total,
                                            'fecha':this.hoy,
                                            'vencimiento':this.hoy,
                                            'orden':this.ndev,
                                            'rutaPDF':resNC.urlPdf,
                                            'rutaXML':resNC.urlXml,
                                            'usuario':this.usu,
                                            'sucursal':this.nSucursal
                                        };
                                        //console.log(paramsDTE);
                                        await this.saveDTE(paramsDTE);                    
                                    } else {
                                        this.Msg='DTE enviado sin registro'
                                        this.msgbox=true;
                                        this.color='warning';
                                        this.showSnack();
                                    }
                                } else {
                                    this.Msg='DTE no enviado'
                                    this.msgbox=true;
                                    this.color='red';
                                    this.showSnack();
                                }
                            } else { 
                                this.Msg='Documento no Valido'
                                this.msgbox=true;
                                this.color='red';
                                this.showSnack(); 
                            }
                        } else { 
                            this.Msg='DTE No autorizado'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                    } else {
                        this.Msg='Seleccione DTE y Pago'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                    this.loading=false;
                } else {
                    this.Msg='Sin Datos para DTE'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } else {
                this.Msg='Devolucion Sin Sucursal'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        async saveDTE(parametrosDTE){
            const response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/DTE/nueva',parametrosDTE);
            const datos=response.data;
            if (datos=='si'){ 
                await this.saveDetalle();
                const ttdev=parseFloat(this.totaldev)+1;
                this.totaldev=ttdev.toString();
            } else {
                this.Msg='DTE enviado sin registro Local'
                this.msgbox=true;
                this.color='warning';
                this.showSnack();
            }
        },
        async saveDetalle(){
            let t=0;
            if (this.items.length!=0) {
                for (var i = 0; i < this.items.length; i++) {
                    var parametros2={
                        'contrato': this.contrato,
                        'orden': this.ndev,
                        'entidad': this.entidad,
                        'tipo': 'Neto',
                        'cv': 'D',
                        'sku':this.items[i].codigo,
                        'producto':this.items[i].producto,
                        'precio':this.items[i].precioitem,
                        'cantidad':this.items[i].cantidad,
                        'bodega': this.items[i].bodega,
                        'observa':'',
                        'usuario':this.usu,
                        'referencia':this.OutID};
                    const response2 = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/compraventa/nueva',parametros2);
                    const datos2=response2.data;
                    //console.log(datos2);
                    if (datos2=='si'){ t++ }
                };
                if (t!=this.items.length) {
                    this.Msg='DTE enviado sin Detalle local';
                    this.msgbox=true;
                    this.color='warning';
                    this.showSnack();
                } else {
                    await this.UpdateStock();                    
                }
            }
        },
        async UpdateStock(){
            let t=0;
            if (this.items.length!=0) {
                for (var i = 0; i < this.items.length; i++) {
                    var parametros1={
                            'contrato': this.contrato,
                            'sku': this.items[i].codigo,
                            'cantidad': this.items[i].cantidad,
                            'signo': '0+',
                            'bodega': this.items[i].bodega,
                            'usuario': this.usu,
                            'observa': '',
                            'origen':'Devolucion'};
                    const response1 = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/stock',parametros1);
                    const datos1=response1.data;
                    if (datos1=='si'){ t++ }
                };
                if (t!=this.items.length) {
                    this.Msg='DTE enviado sin actualizar Stock';
                    this.msgbox=true;
                    this.color='warning';
                    this.showSnack();
                } else {
                    this.btnPrt=true;
                    this.Msg='DTE exitoso';
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                }
            }
        },
        async envioDTE(datoEnvio,datoPDF,datoXML){
            if (datoEnvio.trim()!=''){
                let InstFormData = new FormData();
                InstFormData.append('email' , datoEnvio);
                InstFormData.append('pdf' , datoPDF);
                InstFormData.append('xml' , datoXML);
                const response= await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/server/sender', InstFormData , {headers : {'content-type': 'multipart/form-data'}});
                //console.log(response.data)
            }
        },
    }
}
</script>