<template>
    <div>
        <h3>Venta Mensual ({{Mes}}-{{Anio}})
            <v-progress-linear v-if="rueda==true" rounded height="2" indeterminate color="primary"></v-progress-linear>
        </h3>
        <v-divider></v-divider>
        <div class="mt-1">
        <v-data-table :headers="headerVentas" :items="itemVentas" class="elevation-1" hide-default-footer dark>
          <template v-slot:item.tPromedio="{ item }">
            <v-chip color="indigo" dark> {{ item.tPromedio }}</v-chip>
          </template>
        </v-data-table>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'CarteraVentas',
  props:['usu','contrato'],
  data(){
    return {
      //contrato:'123',
      Mes:'',
      Anio:'',
      headerVentas:[
        {text: 'Ventas (u)',align: 'center',sortable: false, value: 'tVentas'},
        {text: 'Productos (u)',align: 'center',sortable: false, value: 'tProductos'},
        {text: 'Total ($)',align: 'center',sortable: false, value: 'tTotal'},
        {text: 'Promedio Dia ($)',align: 'center',sortable: false, value: 'tPromedio'}
      ],
      itemVentas: [],
      rueda:true,
    }
  },
  async mounted(){
    this.rueda=true;
    try {
      const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/cartera/ventas/'+this.contrato);
      const datos=response.data;
      //console.log(datos);
      this.itemVentas=[];
      if (datos[0].existe=='si'){
        this.Anio=datos[0].Anio
        switch (datos[0].Mes) {
          case '1':
            this.Mes='Ene';
            break;
          case '2':
            this.Mes='Feb';
            break;
          case '3':
            this.Mes='Mar';
            break;
          case '4':
            this.Mes='Abr';
            break;
          case '5':
            this.Mes='May';
            break;
          case '6':
            this.Mes='Jun';
            break;
          case '7':
            this.Mes='Jul';
            break;
          case '8':
            this.Mes='Ago';
            break;
          case '9':
            this.Mes='Sep';
            break;
          case '10':
            this.Mes='Oct';
            break;
          case '11':
            this.Mes='Nov';
            break;
          case '12':
            this.Mes='Dic';
            break;
        }
        this.itemVentas.push({
          tVentas:new Intl.NumberFormat("de-DE").format(parseFloat(datos[0].Ventas)),
          tProductos:new Intl.NumberFormat("de-DE").format(parseFloat(datos[0].Productos)),
          tTotal:new Intl.NumberFormat("de-DE").format(parseInt(datos[0].Total)),
          tPromedio:new Intl.NumberFormat("de-DE").format(parseFloat(Math.round(datos[0].Total/30)).toFixed(2))
          //tPromedio:new Intl.NumberFormat("de-DE").format(parseFloat(datos[0].Promedio))
        }) 
      }     
    } catch (error) {
      console.log(error)
    }
    this.rueda=false;
  }
}
</script>