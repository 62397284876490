<template>
    <div>
        <v-card>
            <v-card-title class="blue-grey white--text">
                PRECIOS
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="2" class="mt-n2">
                            <v-switch @click="verGlobal()" v-model="global" label="Global"></v-switch>
                        </v-col>
                        <v-col cols="7" md="2">
                            <v-text-field class='text-caption' v-model="sku" label="SKU" :disabled="disabledSku" :filled="filledSku" outlined dense required></v-text-field>
                        </v-col>
                        <v-col cols="2" md="1">
                            <v-btn @click="verSku()" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                        </v-col>
                        <v-col cols="2" md="1">
                            <v-btn @click="inventa=true" class="mt-1" small color="indigo" dark rounded><v-icon small>find_replace</v-icon></v-btn>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-alert class='text-caption' v-if="existe=='si'" dense text type="success">
                                Existe !
                            </v-alert>
                            <v-alert class='text-caption' v-else-if="existe=='no'" dense text type="warning">
                                Nuevo !
                            </v-alert>
                            <v-alert class='text-caption' v-else dense text type="info">
                                <span class="text-caption grey--text">Sku: 99999</span>
                            </v-alert>
                        </v-col >
                    </v-row>
                    <v-divider class="mt-n7"></v-divider>
                    <v-row class="mt-0">
                        <v-col cols="12" md="4">
                            <v-row>
                                <v-col cols="12" md="4" class="mt-2">
                                    <strong>Precio Local</strong>
                                </v-col>
                                <v-col cols="4" md="3" class="mt-2 d-flex justify-end">
                                    <span class="text-caption">Utilidad:</span>
                                </v-col>
                                <v-col cols="4" md="3">
                                    <v-text-field @keypress="onlyNumber" class='text-caption' v-model="pLocal" label="%" outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="4" md="1">
                                    <v-btn @click="uLocal()" class="mt-2 ml-n2" x-small color="indigo" dark rounded><v-icon small>check</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="12" md="4">
                            <v-row>
                                <v-col cols="12" md="4" class="mt-2">
                                    <strong>Precio Mayor</strong>
                                </v-col>
                                <v-col cols="4" md="3" class="mt-2 d-flex justify-end">
                                    <span class="text-caption">Utilidad:</span>
                                </v-col>
                                <v-col cols="4" md="3">
                                    <v-text-field @keypress="onlyNumber" class='text-caption' v-model="pMayor" label="%" outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="4" md="1">
                                    <v-btn @click="uMayor()" class="mt-2 ml-n2" x-small color="indigo" dark rounded><v-icon small>check</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="12" md="4">
                            <v-row>
                                <v-col cols="12" md="4" class="mt-2">
                                    <strong>Precio Oferta</strong>
                                </v-col>
                                <v-col cols="4" md="3" class="mt-2 d-flex justify-end">
                                    <span class="text-caption">Utilidad:</span>
                                </v-col>
                                <v-col cols="4" md="3">
                                    <v-text-field @keypress="onlyNumber" class='text-caption' v-model="pOferta" label="%" outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="4" md="1">
                                    <v-btn @click="uOferta()" class="mt-2 ml-n2" x-small color="indigo" dark rounded><v-icon small>check</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="mt-n10">
                        <v-col cols="12" md="12">
                            <v-data-table dense dark :search="likesearch" :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event"></v-data-table>
                        </v-col>
                        <v-col cols="12" md="3" class="mt-n3">
                            <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Producto" dense required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="mt-n3">
                            <v-pagination v-model="page" :length="pageCount"></v-pagination>
                        </v-col>
                        <v-col cols="12" md="3" class="mt-n3">
                            <div class="float-right">
                                <v-btn @click="limpiar()" class="mr-2" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                                <v-btn @click="savePrecios()" class="mr-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions style="margin-top:-35px">
            </v-card-actions>
        </v-card>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />   

        <!-- dialog inventa --> 
        <v-dialog v-model="inventa" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="inventa=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
                <v-spacer>
                    <div class="float-right">
                    <v-text-field class='ml-5 mr-2 text-caption' v-model="inventasearch" label="Buscar Producto" dense required></v-text-field>
                    </div>
                </v-spacer>
            </v-card-title>
            <v-card-text>
                <v-data-table @click:row="handleClick" dense dark :search="inventasearch" :headers="inventatitulo" :items="inventalista" :page.sync="inventapage" :items-per-page="inventaitemsPerPage"  @page-count="inventapageCount = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="inventapage" :length="inventapageCount"  :total-visible="5"></v-pagination>
            </v-card-text>
        </v-card>
        </v-dialog>
     
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 25px !important;
    /* background-color: lightyellow; */
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
name:'CrearProducto', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            sku:'',
            disabledSku:false,
            filledSku:false,
            pLocal:'0',
            pMayor:'0',
            pOferta:'0',
            headers:[
                { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                { text: 'Producto', align: 'center', sortable: true, value: 'producto' },
                { text: 'Precio Compra', align: 'center', sortable: true, value: 'pcompra' },
                { text: 'Precio Local', align: 'center', sortable: true, value: 'plocal' },
                { text: 'Precio Mayor', align: 'center', sortable: true, value: 'pmayor' },
                { text: 'Precio Oferta', align: 'center', sortable: true, value: 'poferta' },
                { text: 'Unidad Medida', align: 'center', sortable: true, value: 'pmedida' },

            ],
            items:[],
            global:false,
            likesearch:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 5,
            existe:'',

            inventa:false,
            inventasearch:'',
            inventatitulo:[
                { text: 'Sku', align: 'center', sortable: true, value: 'iSku', width:'' },
                { text: 'Producto', align: 'start', sortable: true, value: 'iProducto' },
            ],
            inventalista:[],
            inventapage: 1,
            inventapageCount: 0,
            inventaitemsPerPage: 10,


        }
    },
    mounted(){
        this.verProductos()
    },
    methods:{
        handleClick(txtOpcion) {
            this.global=false;
            this.sku=txtOpcion.iSku;
            this.verSku();
            this.inventa=false;
        },
        async verProductos(){
            try {
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/productos/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.inventalista=[];
                    datos.forEach(element => {
                        this.inventalista.push({
                            'iSku':element.Sku,
                            'iProducto':element.Nombre
                        })
                    });
                }
            } catch (error) {
                console.log(error)
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        clsInput(){
            this.sku='';
            this.pLocal='0';
            this.pMayor='0';
            this.pOferta='0';
            this.items=[];
            this.existe='';
            this.likesearch='';
            this.inventasearch='';
        },
        limpiar(){
            this.clsInput();
            this.disabledSku=false;
            this.filledSku=false;
            this.global=false;
        },
        onlyNumber ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        async uLocal(){
            if(this.items.length!=0){
                let valida='no';
                if(!isNaN(this.pLocal)){
                    //console.log('es Numero');
                    this.pLocal=+this.pLocal;
                    valida='si';
                } else {
                    //console.log('NO es Numero');
                    this.pLocal='0';
                    valida='no';
                }
                if(valida!='no'){
                    let array=[];
                    this.items.forEach(element => {
                        const utilidad = Math.round(parseFloat(element.vcompra)*(parseFloat(this.pLocal)/100),0);
                        const ventaLocal=parseFloat(element.vcompra)+utilidad;
                        array.push({
                            sku:element.sku,
                            producto:element.producto,
                            vcompra:element.vcompra,
                            pcompra: new Intl.NumberFormat("de-DE").format(parseFloat(element.vcompra)),
                            vlocal:ventaLocal,
                            plocal: new Intl.NumberFormat("de-DE").format(parseFloat(ventaLocal)),
                            vmayor:element.vmayor,
                            pmayor: new Intl.NumberFormat("de-DE").format(parseFloat(element.vmayor)),
                            voferta:element.voferta,
                            poferta: new Intl.NumberFormat("de-DE").format(parseFloat(element.voferta)),
                            pmedida:element.pmedida
                        });                       
                    });
                    this.items=[];
                    this.items=array;
                }
            } else {
                this.Msg='Sin Datos';
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        async uMayor(){
            if(this.items.length!=0){
                let valida='no';
                if(!isNaN(this.pMayor)){
                    //console.log('es Numero');
                    this.pMayor=+this.pMayor;
                    valida='si';
                } else {
                    //console.log('NO es Numero');
                    this.pMayor='0';
                    valida='no';
                }
                if(valida!='no'){
                    let array=[];
                    this.items.forEach(element => {
                        const utilidad = Math.round(parseFloat(element.vcompra)*(parseFloat(this.pMayor)/100),0);
                        const ventaLocal=parseFloat(element.vcompra)+utilidad;
                        array.push({
                            sku:element.sku,
                            producto:element.producto,
                            vcompra:element.vcompra,
                            pcompra: new Intl.NumberFormat("de-DE").format(parseFloat(element.vcompra)),
                            vlocal:element.vlocal,
                            plocal: new Intl.NumberFormat("de-DE").format(parseFloat(element.vlocal)),
                            vmayor:ventaLocal,
                            pmayor: new Intl.NumberFormat("de-DE").format(parseFloat(ventaLocal)),
                            voferta:element.voferta,
                            poferta: new Intl.NumberFormat("de-DE").format(parseFloat(element.voferta)),
                            pmedida:element.pmedida
                        });                       
                    });
                    this.items=[];
                    this.items=array;
                }
            } else {
                this.Msg='Sin Datos';
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        async uOferta(){
            if(this.items.length!=0){
                let valida='no';
                if(!isNaN(this.pOferta)){
                    //console.log('es Numero');
                    this.pOferta=+this.pOferta;
                    valida='si';
                } else {
                    //console.log('NO es Numero');
                    this.pOferta='0';
                    valida='no';
                }
                if(valida!='no'){
                    let array=[];
                    this.items.forEach(element => {
                        const utilidad = Math.round(parseFloat(element.vcompra)*(parseFloat(this.pOferta)/100),0);
                        const ventaLocal=parseFloat(element.vcompra)+utilidad;
                        array.push({
                            sku:element.sku,
                            producto:element.producto,
                            vcompra:element.vcompra,
                            pcompra: new Intl.NumberFormat("de-DE").format(parseFloat(element.vcompra)),
                            vlocal:element.vlocal,
                            plocal: new Intl.NumberFormat("de-DE").format(parseFloat(element.vlocal)),
                            vmayor:element.vmayor,
                            pmayor: new Intl.NumberFormat("de-DE").format(parseFloat(element.vmayor)),
                            voferta:ventaLocal,
                            poferta: new Intl.NumberFormat("de-DE").format(parseFloat(ventaLocal)),
                            pmedida:element.pmedida
                        });                       
                    });
                    this.items=[];
                    this.items=array;
                }
            } else {
                this.Msg='Sin Datos';
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        async verGlobal(){
            this.clsInput();
            if(this.global===false){
                this.disabledSku=false;
                this.filledSku=false;
            }
            if(this.global===true){
                this.disabledSku=true;
                this.filledSku=true;
                this.verSku();
            }
        },
        async verSku(){
            if(this.sku.trim()!='' && this.global===false){
                this.loading=true;
                this.sku=this.sku.toUpperCase();
                this.pLocal='0';
                this.pMayor='0';
                this.pOferta='0';
                this.items=[];
                try {
                    const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/precios/v3/'+this.contrato+'/'+this.sku);
                    const datos=response.data;
                    if (datos[0].existe=='si'){
                        this.existe='si';
                        this.disabledSku=true;
                        this.filledSku=true;
                        this.items.push(
                            {
                                sku:this.sku,
                                producto:datos[0].Nombre,
                                vcompra:datos[0].PrecioCompra,
                                pcompra: new Intl.NumberFormat("de-DE").format(parseFloat(datos[0].PrecioCompra)),
                                vlocal:datos[0].PrecioLocal,
                                plocal: new Intl.NumberFormat("de-DE").format(parseFloat(datos[0].PrecioLocal)),
                                vmayor:datos[0].PrecioMayor,
                                pmayor: new Intl.NumberFormat("de-DE").format(parseFloat(datos[0].PrecioMayor)),
                                voferta:datos[0].PrecioOferta,
                                poferta: new Intl.NumberFormat("de-DE").format(parseFloat(datos[0].PrecioOferta)),
                                pmedida:datos[0].Medida,
                            }
                        )
                    } else {
                        this.Msg='Sku no Existe';
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }                    
                } catch (error) {
                    this.Msg='Sin Conexion';
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
                this.loading=false;
            }
            if(this.sku.trim()=='' && this.global===true){
                this.loading=true;
                this.pLocal='0';
                this.pMayor='0';
                this.pOferta='0';
                this.items=[];
                try {
                    const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/lista/precios/v3/'+this.contrato);
                    const datos=response.data;
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            this.items.push(
                                {
                                    sku:element.Sku,
                                    producto:element.Nombre,
                                    vcompra:element.PrecioCompra,
                                    pcompra: new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioCompra)),
                                    vlocal:element.PrecioLocal,
                                    plocal: new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioLocal)),
                                    vmayor:element.PrecioMayor,
                                    pmayor: new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioMayor)),
                                    voferta:element.PrecioOferta,
                                    poferta: new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioOferta)),
                                    pmedida:element.Medida,
                                }
                            )                            
                        });
                    } else {
                        this.Msg='Productos no Existen';
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }                                        
                } catch (error) {
                    this.Msg='Sin Conexion';
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();                    
                }
                this.loading=false;
            }
        },
        async savePrecios(){
            if(this.items.length!=0){
                this.loading=true;
                let save='no';
                for (let index = 0; index < this.items.length; index++) {
                    const element = this.items[index];
                    const params={
                        'sku': element.sku,
                        'local':element.vlocal,
                        'mayor':element.vmayor,
                        'oferta':element.voferta
                    };
                    try {
                        const response = await axios.put('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/precios/nuevos/'+this.contrato,params);
                        const datos=response.data;
                        if (datos=='si'){
                            save='si';
                        }                         
                    } catch (error) {
                        console.log(error)                        
                    }
                };
                this.loading=false;
                if(save!='si'){
                    this.Msg='inconsistencia en Precios';
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();

                } else {
                    this.Msg='Cambios Exitosos';
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                }
            } else {
                this.Msg='Sin Datos';
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
    }
}
</script>