import { render, staticRenderFns } from "./DashCompraVenta.vue?vue&type=template&id=af25ce56&scoped=true"
import script from "./DashCompraVenta.vue?vue&type=script&lang=js"
export * from "./DashCompraVenta.vue?vue&type=script&lang=js"
import style0 from "./DashCompraVenta.vue?vue&type=style&index=0&id=af25ce56&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af25ce56",
  null
  
)

export default component.exports